import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { validateEmpty } from "../../shared/helper/validations";
import { useTranslation } from "react-i18next";

export const HolidaysModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  data,
  modalType,
  editRow,
}) => {
  const [values, setValues] = useState({
    name: undefined,
    date: new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
    }),
  });

  const { t } = useTranslation("workingDays");
  const [dateError, setDateError] = useState(undefined);
  const [nameError, setNameError] = useState(undefined);
  const [nameTouched, setNameTouched] = useState(false);

  useEffect(() => {
    validate();
  }, []);

  useEffect(() => {
    validate();
  }, [values, data]);

  useEffect(() => {
    if (modalType === "edit") {
      setValues({
        name: editRow.original.name,
        date: editRow.original.date,
      });
    } else {
      setValues({
        name: "",
        date: new Date().toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
        }),
      });
    }
    setNameTouched(false);
    setNameError("");
    validate();
  }, [open]);

  const formatDateInput = (input) => {
    const cleanedInput = input.replace(/[^\d]/g, "");

    if (cleanedInput.length <= 2) {
      return cleanedInput;
    } else {
      return cleanedInput.slice(0, 2) + "/" + cleanedInput.slice(2, 4);
    }
  };

  const handleDateChange = (e) => {
    const inputValue = formatDateInput(e.target.value);
    setValues({ ...values, date: inputValue });
  };

  const validateDate = (date) => {
    const [day, month] = date.split("/");
    const currentdate = new Date();
    const newdate = new Date(`${currentdate.getFullYear()}-${month}-${day}`);
    return newdate !== null && !isNaN(newdate.getTime()) && date.length === 5;
  };

  const validateHolidayDate = (date) => {
    if (modalType === "edit") {
      return data
        ? !data.some(
          (holiday) =>
            holiday.date === date && holiday._id !== editRow.original._id,
        )
        : true;
    }
    return data ? !data.some((holiday) => holiday.date === date) : true;
  };

  const validateUnique = (name) => {
    name = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (modalType === "edit") {
      return data
        ? !data.some(
          (holiday) =>
            holiday.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase() === name && holiday._id !== editRow.original._id,
        )
        : true;
    }
    return data
      ? !data.some(
        (holiday) =>
          holiday.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase() === name,
      )
      : true;
  };

  const validate = () => {
    validateEmpty(values.date)
      ? validateDate(values.date)
        ? validateHolidayDate(values.date)
          ? setDateError(undefined)
          : setDateError(t("workingDays:validationDateUnique"))
        : setDateError(t("workingDays:validationDate"))
      : setDateError(t("workingDays:validationDateRequired"));
    if (nameTouched) {
      validateEmpty(values.name)
        ? validateUnique(values.name)
          ? setNameError("")
          : setNameError(t("workingDays:validationNameUnique"))
        : setNameError(t("workingDays:validationNameRequired"));
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">
        {modalType === "new"
          ? t("workingDays:newHoliday")
          : t("workingDays:editHoliday")}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              paddingTop: "1rem",
            }}>
            {columns.map((column) =>
              column.accessorKey === "date" ? (
                <TextField
                  id="modal_holiday_date"
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={values.date}
                  required
                  placeholder="dd-mm"
                  onChange={handleDateChange}
                  helperText={dateError}
                  error={dateError}
                />
              ) : (
                <TextField
                  id="modal_holiday_name"
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={values.name}
                  required
                  helperText={nameError}
                  error={nameError}
                  onFocus={() => setNameTouched(true)}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              ),
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button id="modal_holiday_cancel_button" onClick={() => onClose()}>{t("workingDays:cancel")}</Button>
        <Button
          id="modal_holiday_save_button"
          disabled={dateError || nameError || !values.name}
          onClick={() => onSubmit(values)}
          variant="contained">
          {t("workingDays:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
