import { ThemeProvider } from "@emotion/react";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DateRange from "../shared/components/DateRange";
import dayjs from "dayjs";
import Transition from "../shared/helper/transitionDialog";
import { get, post, remove } from "../shared/http/httpService";

import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Checkbox, FormControlLabel, IconButton, Button, Box, Grid, Tooltip, Typography } from "@mui/material";
import { tableDarkTheme, tableLightTheme } from "../shared/theming/table.theme";

// ExcelPlugin
import { mkConfig, generateCsv, download } from "export-to-csv";

// Material UI
import AddCommentIcon from "@mui/icons-material/AddComment";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import NewIntentModal from "./NewIntentModal.component";
import ChatViewer from "./Chat/ChatViewer.component";

const NoMatch = () => {
  const theme = useSelector((state) => state.theme);
  const { t, i18n } = useTranslation("nomatch");
  const [conversation, setConversation] = useState([]);
  const [intents, setIntents] = useState([]);
  const [data, setData] = useState([]);
  const [accountSelected, setAccountSelected] = useState("");
  const [accountNames, setAccountNames] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialogIgnore, setOpenDialogIgnore] = useState(false);
  const [includeNoMatch, setIncludeNoMatch] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(undefined);
  const [dataIncludeNoMatch, setDataIncludeNomatch] = useState([]);
  const [valid, setValid] = useState(true);
  const [fileName, setFileName] = useState(
    t("noMatchFileName") +
      t("from") +
      getParsedDate(startDate) +
      t("to") +
      getParsedDate(endDate),
  );
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let [userPermissionsNoMatchIgnore, setUserPermissionsNoMatchIgnore] =
    React.useState(false);
  const typeOptions = [t("typeSuggestion"), t("typeNoMatch"), t("typeEntity")];

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "noMatch" && permission.fullAccess === true,
    );

    let hasNoMatchIgnorePermissions = user.permissions.some(
      (permission) =>
        permission.name === "noMatchIgnore" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
    setUserPermissionsNoMatchIgnore(hasNoMatchIgnorePermissions);
  };

  const nomatchTotals = useMemo(() => {
    const suggestionText = t("typeSuggestion").toLowerCase();
    let total = data.length;
    let totalWithSuggestions = data.filter(
      (i) => i.type.toLowerCase() === suggestionText,
    ).length;
    let totalWithoutSuggestions = data.filter(
      (i) => i.type.toLowerCase() !== suggestionText,
    ).length;
    return {
      total,
      totalWithSuggestions,
      totalWithoutSuggestions,
    };
  }, [data, t]);

  const columns = useMemo(
    () => [
      {
        header: t("question"),
        accessorKey: "question",
        size: 150,
      },
      {
        header: t("channel"),
        accessorKey: "channel",
        size: 5,
        Cell: (cell) => {
          if (cell.renderedCellValue === "MESSENGER") {
            return "FACEBOOK";
          }
          if (cell.renderedCellValue === "INSTAGRAMDIRECT") {
            return "INSTAGRAM";
          }
          return cell.renderedCellValue;
        },
      },
      { header: t("user"), accessorKey: "user", maxSize: 50 },
      { header: "id chat", accessorKey: "idChat" },
      { header: t("page"), accessorKey: "page" },
      {
        header: t("date"),
        accessorKey: "date",
        filterVariant: "date",
        filterFn: (rows, id, filterValue) => {
          if (!rows.original[id]) {
            return true;
          }
          const rowDate = dayjs(rows.original[id]);
          const filterDate = dayjs(filterValue);
          return rowDate.isSame(filterDate, "day");
        },
        sortingFn: "datetime",
        Cell: ({ cell }) => dayjs(cell.getValue()).format("DD-MM-YYYY"),
      },
      {
        header: t("type"),
        accessorKey: "type",
        size: 20,
        filterVariant: "select",
        filterSelectOptions: typeOptions.sort(),
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          if (filterValue.includes(t("typeSuggestion"))) {
            return rows.original.type === t("typeSuggestion");
          }
          if (filterValue.includes(t("typeNoMatch"))) {
            return rows.original.type === t("typeNoMatch");
          }

          if (filterValue.includes(t("typeEntity"))) {
            return rows.original.type === t("typeEntity");
          }
        },
        Cell: ({ row }) => {
          if (row.original.type === t("typeSuggestion")) {
            return t("typeSuggestion");
          } else if (row.original.type === t("typeEntity")) {
            return t("typeEntity");
          }
          return t("typeNoMatch");
        },
      },
      {
        header: t("time"),
        accessorKey: "time",
        filterFn: (rows, id, filterValue) => {
          if (!rows.original["date"]) {
            return true;
          }
          const rowDate = dayjs(rows.original["date"]).format("HH:mm:ss");
          return rowDate.includes(filterValue);
        },
        Cell: (cell) => dayjs(cell.row.original.date).format("HH:mm:ss"),
      },
    ],
    [i18n.language],
  );

  const { enqueueSnackbar } = useSnackbar();
  const client = useSelector((state) => state.client);

  useEffect(() => {
    getUserPermisions();
    getAccountNames();
  }, [client.name]);

  useEffect(() => {
    setData([]);
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().startOf("day"));
  }, [client.name]);

  useEffect(() => {
    getNoMatch();
  }, [includeNoMatch]);

  const handleOpen = (noMatch) => {
    let room = noMatch.original.user;
    let question = noMatch.original.question;
    let idChat = noMatch.original.idChat;
    get("/report/conversationByIdChat", { room, question, idChat })
      .then(async (data) => {
        data.conversations.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        setConversation(data.conversations);
        setOpen(true);
      })
      .catch(() => {
        enqueueSnackbar(t("messageErrorGettingConversation"), {
          variant: "error",
        });
      });
  };

  const handleExportRows = (rows) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const rowData = rows.map((row) => {
      return {
        [t("questionRow")]: `""${row.original.question}""`,
        [t("channelRow")]: row.original.channel,
        [t("pageRow")]: row.original.page,
        [t("dateRow")]: Moment(row.original.date).format("DD-MM-YYYY"),
        [t("timeRow")]: Moment(row.original.date).format("HH:mm:ss"),
        [t("typeRow")]: row.original.type,
      };
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig, fileName)(csv);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAccountNames = () => {
    get("/essentials/data/agents")
      .then(async (data) => {
        data.agents = orderAlphabeticallyWithAttribute(
          data.agents,
          "display_name",
        );
        let default_agent = defaultAgent(data.agents);
        setAccountNames(data.agents);
        setAccountSelected(default_agent.name);
      })
      .catch(() => {
        enqueueSnackbar(t("messageErrorGettingAccountNames"), {
          variant: "error",
        });
      });
  };

  const getNoMatch = async () => {
    if (accountSelected) {
      get("/noMatch", {
        agent: accountSelected,
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
      })
        .then(async (data) => {
          if (data.length !== 0) {
            data.forEach(function (nomatch) {
              if (nomatch.channel === "MESSENGER") {
                nomatch.channel = "FACEBOOK";
              } else if (nomatch.channel === "INSTAGRAMDIRECT") {
                nomatch.channel = "INSTAGRAM";
              }
              if (nomatch.type === "suggestion") {
                nomatch.type = t("typeSuggestion");
              } else if (nomatch.type === "noMatch") {
                nomatch.type = t("typeNoMatch");
              } else {
                nomatch.type = t("typeEntity");
              }
            });
          }
          let data_excludes = data.filter((i) => i.createdIntent === true);
          if (!includeNoMatch) {
            data = data.filter((i) => i.createdIntent !== true);
            data = data.filter(
              (i) =>
                !data_excludes.some(
                  (nomatch) => nomatch.question === i.question,
                ),
            );
          }

          setData(data);
          setDataIncludeNomatch(data_excludes);
        })
        .catch(() => {
          enqueueSnackbar(t("mesaggeErrorGettingNoMatch"), {
            variant: "error",
          });
        });
    }
  };

  const handleChangeAccount = async (e) => {
    setAccountSelected(e.target.value);
  };

  const getIntents = () => {
    if (accountSelected) {
      get("/intents?agent=" + accountSelected)
        .then(async (data) => {
          let intents = data.sort((a, b) => a.name.localeCompare(b.name));
          setIntents(intents);
        })
        .catch(() => {
          enqueueSnackbar(t("mesaggeErrorGettingIntents"), {
            variant: "error",
          });
        });
    }
  };

  const openTheModal = (row) => {
    let exclude = dataIncludeNoMatch.filter(
      (i) => i.question === row.original.question,
    );
    if (exclude.length > 0) return;
    if (row.original.createdIntent) return;
    setOpenModal(true);
    setSelectedIntent(row.original);
  };

  const handleSaveIntent = () => {
    setOpenModal(false);
    setSelectedIntent(undefined);
    fetch();
  };

  const handleCloseIntent = () => {
    setOpenModal(false);
    setSelectedIntent(undefined);
  };

  const openTheDialogIgnore = (row) => {
    setOpenDialogIgnore(true);
    setSelectedIntent(row.original);
  };

  const handleDate = function (dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      setValid(dates.valid);
      let newEndDate = new Date(dates.endDate);
      setFileName(
        t("noMatchFileName") +
          t("from") +
          getParsedDate(dates.startDate) +
          t("to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  };

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  const handleCloseDialogIgnore = () => {
    setOpenDialogIgnore(false);
    setSelectedIntent(undefined);
  };

  const handleSaveDialogIgnore = async () => {
    const body = {
      question: selectedIntent.question,
      agent: accountSelected,
    };
    await post("/noMatchIgnore", body)
      .then(() => {
        enqueueSnackbar(t("messageNoMatchIgnoreAdded"), {
          variant: "success",
        });
      })
      .catch((errorMessage) => {
        enqueueSnackbar(errorMessage.message, {
          variant: "error",
        });
      });
    await remove("/noMatch?agent=" + accountSelected, {
      question: selectedIntent.question,
    }).catch(() => {
      enqueueSnackbar(t("messageErrorDeletingSelectedValues"), {
        variant: "error",
      });
    });
    await getNoMatch();

    setOpenDialogIgnore(false);
    setSelectedIntent(undefined);
  };

  const handleIncludeNoMatch = (e) => {
    setIncludeNoMatch(e.target.checked);
  };

  const fetch = () => {
    getIntents();
    getNoMatch();
  };

  const localeMap = {
    ES: "es",
    EN: "en",
  };

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    defaultColumn: {
      minSize: 20,
      maxSize: 100,
      size: 80,
    },
    enableDelete: true,
    editDisplayMode: "modal",
    enableEditing: true,
    initialState: { columnVisibility: { idChat: false } },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title={t("tooltipViewConversation")}>
          <IconButton
            id="view-conversation-icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(row)}>
            <LibraryBooksIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            userPermissions
              ? t("tooltipViewAddIntent")
              : t("noPermissionTooltip")
          }>
          <span>
            <IconButton
              id="add-intent-icon"
              disabled={!userPermissions}
              style={{
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onClick={() => openTheModal(row)}>
              <AddCommentIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            userPermissionsNoMatchIgnore
              ? t("ignoreNomatch")
              : t("noPermissionTooltip")
          }>
          <span>
            <IconButton
              id="clear-icon"
              disabled={!userPermissionsNoMatchIgnore}
              sx={{ color: "deleteIcon.color" }}
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => openTheDialogIgnore(row)}>
              <ClearIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
          ml: 1,
          backgroundColor: "boxAnalytics.background",
        }}>
        <Grid container spacing={1} alignItems="center" flexWrap="nowrap">
          <Grid item xs={2}>
            <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { xs: "0.9rem", md: "1.2rem" } }}>
              {t("total", { total: nomatchTotals.total })}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", md: "0.9rem" } }}>
              {t("withSuggestions", { total: nomatchTotals.totalWithSuggestions })}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", md: "0.9rem" } }}>
              {t("withoutSuggestions", { total: nomatchTotals.totalWithoutSuggestions })}
            </Typography>
          </Grid>

          <Grid item xs={7} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: 3 }}>
            <DateRange startDate={dayjs(startDate)} endDate={dayjs(endDate)} onDateSelected={handleDate} language={i18n.language} />

            <FormControl variant="standard" sx={{ width: { xs: "120px", md: "180px" } }}>
              <InputLabel id="agent-label">{t("agent")}</InputLabel>
              <Select id="combo-agent" value={accountSelected} onChange={handleChangeAccount}>
                {accountNames.map((item) => (
                  <MenuItem key={item._id} value={item.name}>{item.display_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
              <FormControlLabel
                control={<Checkbox checked={includeNoMatch} onChange={handleIncludeNoMatch} />}
                label={
                  <Typography sx={{ fontSize: { xs: "1 rem", md: "0.9 rem" }, lineHeight: 1.1 }}>
                    {t("includeAll")}
                  </Typography>
                }
              />
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: 1 }}>
            <Button
              id="fetch-button"
              variant="contained"
              className={"WithSnackbar(NoMatch)-button-2"}
              size="medium"
              onClick={fetch}
              disabled={!valid}
              sx={{ minWidth: { xs: "80px", md: "100px" }, height: { xs: "32px", md: "40px" }, fontSize: { xs: "0.75rem", md: "0.9rem" } }}>
              {t("fetch")}
            </Button>
            <Button
              id="WithSnackbar(NoMatch)-button-2"
              className={"WithSnackbar(NoMatch)-button-2"}
              variant="contained"
              size="medium"
              sx={{ minWidth: { xs: "80px", md: "100px" }, height: { xs: "32px", md: "40px" }, fontSize: { xs: "0.75rem", md: "0.9rem" } }}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              disabled={table.getPrePaginationRowModel().rows.length === 0}>
              {t("conversationPerDay:download")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    ),
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={localeMap[i18n.language]}>
      <NewIntentModal
        open={openModal}
        intent={selectedIntent}
        agent={accountSelected}
        intents={intents}
        onSave={handleSaveIntent}
        onClose={handleCloseIntent}
        data={data}
        translations={t}
        enqueueSnackbar={enqueueSnackbar}
      />
      <div>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {t("nomatch")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth>
        <DialogTitle>{t("conversation")}</DialogTitle>
        <DialogContent>
          <ChatViewer conversation={conversation}></ChatViewer>
        </DialogContent>
        <DialogActions>
          <Button id="closeBtn" onClick={handleClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={openDialogIgnore}
        onClose={handleCloseDialogIgnore}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>{t("ignoreNomatch")}</DialogTitle>
        <DialogContent>
          <Trans>
            <p>
              {t("confirmIgnoreNoMatch", {
                intent: selectedIntent ? selectedIntent.question : "",
              })}
            </p>
          </Trans>
        </DialogContent>
        <DialogActions>
          <Button id="confirmBtn" onClick={handleSaveDialogIgnore}>{t("confirm")}</Button>
          <Button id="cancelBtn" onClick={handleCloseDialogIgnore}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default NoMatch;
