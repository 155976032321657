import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, TextField, Tooltip } from "@mui/material";
import DynamicQuickReplies from "./DynamicQuickReplies";
import StaticQuickReplies from "./StaticQuickReplies";
import "./QuickReplies.css";

export default function QuickReplies(props) {
  const [repliesFromArray, setRepliesFromArray] = useState(false);
  const [quickReplies, setQuickReplies] = useState({});
  const [errors, setErrors] = useState({});
  const [errorIndex, setErrorIndex] = useState({});
  const [valid, setValid] = useState(true);
  const [dynamicDirty, setDynamicDirty] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (props.editNode?.data) {
      props.updateErrors(errors, errorIndex, valid);
    }
    update();
  }, [errors, errorIndex, valid]);

  useEffect(() => {
    if (props.editNode?.data) {
      editQuickReplies(props.editNode?.data);
    } else if (props.intents && props.quickReplies) {
      editQuickReplies(props.quickReplies);
    }
    validate();
  }, [props.editNode?.id]);

  useEffect(() => {
    validate();
  }, [quickReplies]);

  const update = () => {
    if (props.editNode?.data && Object.keys(quickReplies).length > 0) {
      let editNode = props.editNode;
      editNode.data.quickReplies = quickReplies.static;
      editNode.data.quickRepliesName = quickReplies.quickRepliesName;
      editNode.data.quickRepliesDynamic = quickReplies.quickRepliesDynamic;
      props.updateQuickReplies(props.editNode, errors, errorIndex, valid);
    } else if (props.intents && props.quickReplies) {
      let quickRepliesIntent = props.quickReplies;
      quickRepliesIntent.static = quickReplies.static;
      quickRepliesIntent.quickRepliesDynamic = quickReplies.quickRepliesDynamic;
      quickRepliesIntent.quickRepliesName = quickReplies.quickRepliesName;
      quickRepliesIntent.quickRepliesSwitch = repliesFromArray;
      props.updateQuickReplies(quickRepliesIntent, errors, errorIndex, !valid);
    }
  };

  const editQuickReplies = (quickReplies) => {
    let tempQuickReplies = {};

    tempQuickReplies.static = quickReplies.quickReplies;
    tempQuickReplies.quickRepliesName = quickReplies.quickRepliesName;
    tempQuickReplies.quickRepliesDynamic = quickReplies.quickRepliesDynamic;

    if (
      quickReplies.quickRepliesDynamic?.length > 0 &&
      quickReplies.quickRepliesDynamic[0]?.arrayName !== undefined
    ) {
      setRepliesFromArray(true);
      let tempDirty = {
        arrayName: false,
        value: false,
        label: false,
        description: false,
      };
      if (quickReplies.quickRepliesDynamic[0].arrayName) {
        tempDirty.arrayName = true;
      }
      if (quickReplies.quickRepliesDynamic[0].value) {
        tempDirty.value = true;
      }
      if (quickReplies.quickRepliesDynamic[0].label) {
        tempDirty.label = true;
      }
      if (quickReplies.quickRepliesDynamic[0].description) {
        tempDirty.description = true;
      }
      setDynamicDirty(tempDirty);
    } else {
      setRepliesFromArray(false);
    }

    setQuickReplies(tempQuickReplies);
  };

  const updateQuickRepliesName = (e) => {
    let edit = quickReplies;
    edit.quickRepliesName = e.target.value;
    setQuickReplies({ ...edit });
  };

  const validate = () => {
    const errors = {};
    const errorIndex = {};
    let isValid = false;

    if (isDynamicMode()) {
      isValid = validateDynamicReplies(errors);
    } else {
      errorIndex.quickRepliesValue = [];
      errorIndex.quickRepliesLabel = [];
      errorIndex.quickRepliesLabelLong = [];
      errorIndex.quickRepliesDescriptionLong = [];

      const vQR = validateStaticReplies(errors, errorIndex);
      isValid = Object.keys(errors).length === 0 && vQR;
    }

    setErrorIndex(errorIndex);
    setErrors(errors);
    setValid(isValid);
  };

  const isDynamicMode = () => {
    return (
      Object.keys(dynamicDirty).length > 0 ||
      (repliesFromArray && quickReplies.quickRepliesDynamic)
    );
  };

  const validateDynamicReplies = (errors) => {
    if (
      !repliesFromArray ||
      !quickReplies.quickRepliesDynamic ||
      quickReplies.quickRepliesDynamic.length === 0
    ) {
      return false;
    }
    validateDynamicArrayName(errors);
    validateDynamicLabel(errors);
    validateDynamicValue(errors);
    validateDynamicDescription(errors);
    return (
      dynamicDirty.arrayName === true &&
      dynamicDirty.label === true &&
      dynamicDirty.value === true &&
      Object.keys(errors).length === 0
    );
  };

  const validateDynamicArrayName = (errors) => {
    if (
      dynamicDirty.arrayName &&
      quickReplies.quickRepliesDynamic[0].arrayName === ""
    ) {
      errors.quickRepliesArray = t("flowgraph:errorQRDynamicArray");
    }
  };

  const validateDynamicLabel = (errors) => {
    if (dynamicDirty.label && quickReplies.quickRepliesDynamic[0].label === "") {
      errors.quickRepliesLabel = t("flowgraph:errorQRDynamicLabel");
    }
  };

  const validateDynamicValue = (errors) => {
    if (dynamicDirty.value && quickReplies.quickRepliesDynamic[0].value === "") {
      errors.quickRepliesValue = t("flowgraph:errorQRDynamicValue");
    }
  };

  const validateDynamicDescription = (errors) => {
    if (
      dynamicDirty.description &&
      quickReplies.quickRepliesDynamic[0].description &&
      quickReplies.quickRepliesDynamic[0].description.length > 72
    ) {
      errors.quickRepliesDescription = t(
        "flowgraph:errorQRDynamicDescriptionLong"
      );
    }
  };

  const validateStaticReplies = (errors, errorIndex) => {
    if (!quickReplies.static || quickReplies.static.length === 0) {
      return true;
    }

    errorIndex.quickRepliesValue = [];
    errorIndex.quickRepliesLabel = [];
    errorIndex.quickRepliesLabelLong = [];
    errorIndex.quickRepliesDescriptionLong = [];

    let vQR = true;

    for (let i = 0; i < quickReplies.static.length; i++) {
      if (quickReplies.static[i]) {
        vQR = validateSingleStaticReply(errors, errorIndex, i, vQR);
      }
    }

    return vQR;
  };

  const validateSingleStaticReply = (errors, errorIndex, index, vQR) => {
    const reply = quickReplies.static[index];
    
    if (!reply) return vQR;

    if (reply.label === null || reply.value === null) {
      return false;
    }
    validateStaticLabel(errors, errorIndex, index, reply);
    validateStaticValue(errors, errorIndex, index, reply);
    validateStaticDuplicates(errors, errorIndex, index);

    return vQR;
  };

  const validateStaticLabel = (errors, errorIndex, index, reply) => {

    if (reply.label === "") {
      errors.quickRepliesLabel = t("flowgraph:errorTitleIsRequired");
      errorIndex.quickRepliesLabel.push(index);
    }
    
    if (reply.label && reply.label.length > 20) {
      errors.quickRepliesLabelLong = t("flowgraph:errorTitleTooLarge");
      errorIndex.quickRepliesLabelLong.push(index);
    }
  };

  const validateStaticValue = (errors, errorIndex, index, reply) => {
    if (reply.value === "") {
      errors.quickRepliesValue = t("flowgraph:errorPlayloadIsRequired");
      errorIndex.quickRepliesValue.push(index);
    }

    if (reply.description && reply.description.length > 72) {
      errors.quickRepliesDescriptionLong = t("flowgraph:errorDescriptionTooLarge");
      errorIndex.quickRepliesDescriptionLong.push(index);
    }
  };

  const validateStaticDuplicates = (errors, errorIndex, index) => {
    for (let j = 0; j < quickReplies.static.length; j++) {
      if (index === j) continue;
      
      // Duplicate value
      if (quickReplies.static[index].value === quickReplies.static[j].value) {
        errors.quickRepliesValue = t("flowgraph:errorPlayloadDuplicated");
        errorIndex.quickRepliesValue.push(index);
      }
      
      // Duplicate label
      if (quickReplies.static[index].label === quickReplies.static[j].label) {
        errors.quickRepliesLabel = t("flowgraph:errorLabelDuplicated");
        errorIndex.quickRepliesLabel.push(index);
      }
    }
  };

  const enableQuickRepliesFromArray = () => {
    setRepliesFromArray(!repliesFromArray);
    validate();
    let edit = quickReplies;
    setDynamicDirty({});
    edit.quickRepliesDynamic = [{}];
    edit.static = [];
    setQuickReplies({ ...edit });
  };

  return (
    <div>
      <Tooltip
        title={!props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""}
      >
        <TextField
          id="quickreply-payload"
          label={t("flowgraph:quickRepliesName")}
          variant="standard"
          className="transition-message"
          disabled={!props.userPermissions}
          InputLabelProps={{ shrink: true }}
          value={
            quickReplies.quickRepliesName === undefined
              ? ""
              : quickReplies.quickRepliesName
          }
          onChange={(e) => {
            updateQuickRepliesName(e);
          }}
        />
      </Tooltip>
      <div>
        <FormControlLabel
          control={
            <Tooltip
              title={!props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""}
            >
              <span>
                <Switch
                  id="quick-replies-switch-mode"
                  disabled={!props.userPermissions}
                  checked={repliesFromArray}
                  onChange={enableQuickRepliesFromArray}
                />
              </span>
            </Tooltip>
          }
          label={
            !repliesFromArray
              ? t("flowgraph:staticMode")
              : t("flowgraph:dynamicMode")
          }
        />
      </div>
      {repliesFromArray ? (
        <DynamicQuickReplies
          quickRepliesDynamic={quickReplies.quickRepliesDynamic}
          setQuickRepliesDynamic={(updatedDynamic) =>
            setQuickReplies((prev) => ({ ...prev, quickRepliesDynamic: updatedDynamic }))
          }
          userPermissions={props.userPermissions}
          errors={errors}
          setDirty={setDynamicDirty}
        />
      ) : (
        <StaticQuickReplies
          quickRepliesStatic={Array.isArray(quickReplies.static) ? quickReplies.static : []}
          setQuickRepliesStatic={(updatedStatic) =>
            setQuickReplies((prev) => ({ ...prev, static: updatedStatic }))
          }
          userPermissions={props.userPermissions}
          isAddDisabled={props.isAddDisabled}     
          intents={props.intents} 
          errors={errors}
          errorIndex={errorIndex}
        />
      )}
    </div>
  );
}

QuickReplies.propTypes = {
  userPermissions: PropTypes.bool,
  quickReplies: PropTypes.object,
  editNode: PropTypes.object,
  intents: PropTypes.array,
  isAddDisabled: PropTypes.bool,
  updateQuickReplies: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
};
