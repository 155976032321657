import Paper from "@mui/material/Paper";
import React, { useState, useMemo, useEffect } from "react";
import { get } from "../../shared/http/httpService";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
// ExcelPlugin
import { mkConfig, generateCsv, download } from "export-to-csv";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "end",
  },
  button: {
    marginLeft: "10px",
    marginRight: "10px",
    backgroundColor: "#D9D9D9",
    color: "black",
  },
}));

const ExpressReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("expressReport");

  const classes = useStyles();
  const [valid, setValid] = useState(true);
  const [tableData, setTableData] = useState([]);

  const theme = useSelector((state) => state.theme);

  const channelMappings = {
    WHATSAPP: "WhatsApp",
    MESSENGER: "Facebook",
    WEB: "Web",
    INSTAGRAMDIRECT: "Instagram",
    WEBCHAT: "Webchat",
    msteams: "Teams",
  };

  const channelFilterOptions = Object.keys(channelMappings)
    .map((key) => ({
      value: key,
      text: channelMappings[key],
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [fileName, setFileName] = useState(
    t("expressReport") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );

  const client = useSelector((state) => state.client);
  useEffect(() => {
    setTableData([]);
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().startOf("day"));
  }, [client.name]);

  const columns = useMemo(
    () => [
      {
        header: "Darwin Chat ID",
        accessorKey: "darwinChatId",
        size: 150,
      },
      {
        header: t("expressReportTagName"),
        accessorKey: "expressReport",
        size: 150,
      },
      {
        header: t("message"),
        accessorKey: "message",
        size: 150,
        Cell: ({ cell }) => (
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {cell.getValue()}
          </div>
        ),
      },
      {
        header: t("channel"),
        accessorFn: (original) => original.channel,
        filterVariant: "multi-select",
        filterSelectOptions: channelFilterOptions,
        Cell: ({ cell }) => channelMappings[cell.getValue()],
        size: 150,
        filterFn: (row, id, filter) => {
          if (filter.length === 0) {
            return true;
          }
          return filter.includes(row.original.channel);
        },
      },
      {
        header: t("origin"),
        accessorKey: "user",
        size: 150,
      },
      {
        header: t("date"),
        accessorFn: (original) => new Date(original.date).toLocaleString(),
        size: 150,
      },
    ],
    [t],
  );

  const fetchExpressReport = async () => {
    try {
      const data = await get("/report/expressReport", {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
      });

      prepareAndSetDataTable(data);
    } catch (error) {
      if (error.status === 202) {
        enqueueSnackbar(t("analytics:timeoutHandlingReport"), {
          variant: "error",
        });
      } else {
        // Handle other errors
        console.error("Error fetching Express Report:", error);
        throw error;
      }
    }
  };

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:ExpressReport") +
          " " +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    className: classes.zIndex0,
    style: {
      paddingLeft: "30px",
      paddingRight: "30px",
      display: "block",
    },
  });

  const handleExportRows = (rows) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const rowData = rows.map((row) => {
      return {
        [t("origin")]: row.original.user,
        [t("name")]: row.original.expressReport,
        [t("message")]: row.original.message,
        [t("channel")]: row.original.channel,
        [t("date")]: row.original.date,
        "ID Darwin Chat": row.original.darwinChatId,
      };
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig, fileName)(csv);
  };

  const prepareAndSetDataTable = (data) => {
    // Order by date
    data.sort((a, b) => {
      return new Date(b.Messages[0]?.Date) - new Date(a.Messages[0]?.Date);
    });

    let tablesRows = [];
    data?.forEach((row) => {
      const darwinChatId = row.ID;
      let expressReport = row.Messages[0]?.ExpressReport;
      row.Messages.forEach((message) => {
        if (message.ExpressReport !== "") {
          expressReport = message.ExpressReport;
        }
        const tableRow = {
          user: message.User,
          expressReport: expressReport,
          channel: message.Channel,
          date: message.Date,
          darwinChatId: darwinChatId,
          message: message.Message,
        };

        if (tableRow.user !== "system" && tableRow.message !== "") {
          tablesRows.push(tableRow);
        }
      });
      setTableData(tablesRows);
    });
  };

  return (
    <div>
      <Paper
        style={{
          marginBottom: "15px",
          borderRadius: "15px",
        }}>
        <Box
          sx={{
            p: 3,
            borderRadius: "15px",
            backgroundColor: "boxAnalytics.background",
          }}>
          <Typography style={{ marginBottom: 20 }} variant="h4">
            {t("expressReportTitle")}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={3} display="flex">
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onDateSelected={handleDate}
                maxOneWeek={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              display="flex"
              flexWrap="wrap"
              justifyContent="right"
              alignItems="center">
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  gap: "10px",
                }}>
                <Button
                  id="fetch-button"
                  className={classes.button}
                  variant="contained"
                  color="default"
                  size="medium"
                  disabled={!valid}
                  onClick={fetchExpressReport}
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("fetch")}
                </Button>
                <Button
                  id="download-button"
                  className={classes.button}
                  variant="contained"
                  color="default"
                  size="medium"
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => {
                    handleExportRows(table.getPrePaginationRowModel().rows);
                  }}
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("download")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div style={{ maxWidth: "100%" }}>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
    </div>
  );
};
export default ExpressReport;
