import React from "react";
import PropTypes from "prop-types";
import { TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DynamicQuickReplies({
  quickRepliesDynamic,
  setQuickRepliesDynamic,
  userPermissions,
  errors,
  setDirty,
}) {
  const { t } = useTranslation();

  const getQuickRepliesName = (quickReply) => 
    quickRepliesDynamic?.[0]?.[quickReply] || "";

  const updateQuickRepliesArray = (e) => {
    let edit = quickRepliesDynamic || [];
    if (edit.length === 0) {
      edit = [{ arrayName: "", label: "", value: "" }];
    }
    edit[0].arrayName = e.target.value;
    setDirty((prev) => ({ ...prev, arrayName: true }));
    setQuickRepliesDynamic(edit);
  };

  const updateQuickRepliesDynamicValue = (e) => {
    let edit = quickRepliesDynamic || [];
    if (edit.length === 0) {
      edit = [{ arrayName: "", label: "", value: "" }];
    }
    edit[0].value = e.target.value;
    setDirty((prev) => ({ ...prev, value: true }));
    setQuickRepliesDynamic(edit);
  };

  const updateQuickRepliesDynamicLabel = (e) => {
    let edit = quickRepliesDynamic || [];
    if (edit.length === 0) {
      edit = [{ arrayName: "", label: "", value: "" }];
    }
    edit[0].label = e.target.value;
    setDirty((prev) => ({ ...prev, label: true }));
    setQuickRepliesDynamic(edit);
  };

  const updateQuickRepliesDynamicDescription = (e) => {
    let edit = quickRepliesDynamic || [];
    if (edit.length === 0) {
      edit = [{ arrayName: "", label: "", value: "" }];
    }
    edit[0].description = e.target.value;
    setDirty((prev) => ({ ...prev, description: true }));
    setQuickRepliesDynamic(edit);
  };

  return (
    <div>
      <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
        <TextField
          fullWidth
          id="quick-reply-array"
          label={t("flowgraph:quickRepliesArray")}
          variant="standard"
          className="transition-message"
          disabled={!userPermissions}
          error={!!errors.quickRepliesArray}
          helperText={errors.quickRepliesArray}
          style={{ display: "block" }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("arrayName") === undefined
              ? ""
              : getQuickRepliesName("arrayName")
          }
          onChange={(e) => {
            updateQuickRepliesArray(e);
          }}
        />
      </Tooltip>
      <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
        <TextField
          fullWidth
          id="quickreply-label"
          label={t("flowgraph:quickRepliesLabel")}
          variant="standard"
          className="transition-message"
          disabled={!userPermissions}
          error={!!errors.quickRepliesLabel}
          helperText={errors.quickRepliesLabel}
          style={{ display: "block", marginTop: "15px" }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("label") === undefined
              ? ""
              : getQuickRepliesName("label")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicLabel(e);
          }}
        />
      </Tooltip>
      <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
        <TextField
          fullWidth
          id="quick-reply-value"
          label={t("flowgraph:quickRepliesValue")}
          variant="standard"
          disabled={!userPermissions}
          error={!!errors.quickRepliesValue}
          helperText={errors.quickRepliesValue}
          className="transition-message"
          style={{ display: "block", marginTop: "15px" }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("value") === undefined
              ? ""
              : getQuickRepliesName("value")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicValue(e);
          }}
        />
      </Tooltip>
      <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
        <TextField
          fullWidth
          id="quick-reply-description"
          label={t("flowgraph:quickRepliesDescription")}
          variant="standard"
          disabled={!userPermissions}
          className="transition-message"
          error={!!errors.quickRepliesDescription}
          helperText={errors.quickRepliesDescription}
          style={{ display: "block", marginTop: "15px" }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("description") === undefined
              ? ""
              : getQuickRepliesName("description")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicDescription(e);
          }}
        />
      </Tooltip>
    </div>
  );
}

DynamicQuickReplies.propTypes = {
  quickRepliesDynamic: PropTypes.array,
  setQuickRepliesDynamic: PropTypes.func.isRequired,
  userPermissions: PropTypes.bool,
  errors: PropTypes.object,
  setDirty: PropTypes.func.isRequired,
};
