import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  TextField,
  Switch,
  Input,
  Tooltip,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import ChipInput from "../../shared/components/ChipInput";
import { MaterialReactTable } from "material-react-table";
import { Edit } from "@mui/icons-material";
import EditAgentVarsModal from "./EditAgentVarsModal.Component";
import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import { useSnackbar } from "notistack";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useSelector } from "react-redux";
import { get, put } from "../../shared/http/httpService";
import InfoIcon from "@mui/icons-material/Info";

const varNameTranslation = {
  core_end_session_message: "agents_vars_core_end_session_message",
  flows_retry: "agents_vars_flows_retry",
  flows_retry_transfer: "agents_vars_flows_retry_transfer",
  core_transfer_page: "agents_vars_core_transfer_page",
  core_check_time_page: "agents_vars_core_check_time_page",
  character_limit: "agents_vars_character_limit",
  character_limit_message: "agents_vars_character_limit_message",
  chat_timeout: "agents_vars_chat_timeout",
  chat_timeout_messages: "agents_vars_chat_timeout_messages",
  no_match_message_alt: "agents_vars_no_match_message_alt",
  multi_intent: "agents_vars_multi_intent",
  multi_intent_offset: "agents_vars_multi_intent_offset",
  multi_intent_qa: "agents_vars_multi_intent_qa",
  chat_timeout_double: "agents_vars_chat_timeout_double",
  flows_feedback: "agents_vars_flows_feedback",
  flows_feedback_timeout: "agents_vars_flows_feedback_timeout",
  flows_feedback_timeout_messages:
    "agents_vars_flows_feedback_timeout_messages",
  flows_feedback_steps: "agents_vars_flows_feedback_steps",
  flows_feedback_page_prefix: "agents_vars_flows_feedback_page_prefix",
  no_match_button: "agents_vars_no_match_button",
  no_match_messages: "agents_vars_no_match_messages",
  flows_restart_default_agent: "agents_vars_flows_restart_default_agent",
  no_media_message: "agents_vars_no_media_message",
  page_timeout: "agents_vars_page_timeout",
  text_to_speech: "agents_vars_text_to_speech",
  speech_to_text: "agents_vars_speech_to_text",
  ia_no_match_alternative: "agents_vars_ia_no_match_alternative",
  time_zone: "agents_vars_time_zone",
};

const VarNameCell = ({ cell, varNameTranslation }) => {
  const { t } = useTranslation("agents");

  return (
    <div>
      <Tooltip
        arrow
        placement="top"
        sx={{ marginRight: "10px", cursor: "pointer" }}
        title={
          <Trans>
            {t(varNameTranslation[cell.row.original.var_name] + "_tooltip")}
          </Trans>
        }>
        <InfoIcon />
      </Tooltip>
      {t(varNameTranslation[cell.row.original.var_name])}
    </div>
  );
};

const VarValueCell = ({ var_type, var_value, onChange, agentPages }) => {
  const { t } = useTranslation("agents");

  switch (var_type) {
    case "sarray":
      return (
        <ChipInput
          disableUnderline
          name="value"
          disabled
          source="value"
          value={var_value}
        />
      );
    case "bool":
      return (
        <Switch id="bool-value" name="value" disabled checked={var_value} />
      );
    case "int":
      return (
        <Input
          id="int-value"
          fullWidth
          name="value"
          disabled
          placeholder="Value"
          defaultValue={var_value}
          value={var_value}
          type="number"
        />
      );
    case "float":
      return (
        <TextField
          id="float-value"
          fullWidth
          name="value"
          disabled
          placeholder="Value"
          defaultValue={var_value}
          type="number"
          value={var_value}
          inputProps={{ step: "0.1" }}
        />
      );
    case "object":
      return (
        <div>
          <TextField
            id="object-label"
            variant="standard"
            value={var_value.label}
            disabled
            label={t("AgentVarObjectTitle")}
          />
          <TextField
            id="object-payload"
            style={{ marginLeft: "10px" }}
            variant="standard"
            label={t("AgentVarObjectPayload")}
            disabled
            value={var_value.value}
          />
        </div>
      );
    case "page":
      return (
        <TextField
          id="page-value"
          disabled
          value={
            agentPages.find(
              (page) => page.id === var_value || page.data?.label === var_value, // Look for the page by id or label
            )?.data?.label || var_value // If the page is not found, use the value
          }
          variant="standard"
          className="transition-message"
          fullWidth
        />
      );
    default:
      return (
        <Input
          name="value"
          id="default-value"
          placeholder="Value"
          disabled
          defaultValue={var_value}
          value={var_value}
          multiline
          onChange={(e) => onChange?.(e.target.value)}
        />
      );
  }
};

const AgentVars = ({ vars, getAgents, userPermissions }) => {
  const { t, i18n } = useTranslation("agents");
  const [selectedAgentVar, setSelectedAgentVar] = useState({});
  const [openEditAgentVarModal, setOpenEditAgentVarModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [agentVar, setAgentVar] = useState({});
  const theme = useSelector((state) => state.theme);
  const [agentPages, setAgentPages] = useState([]);
  const columns = useMemo(() => [
    {
      header: t("name"),
      accessorFn: (original) => original.var_name, //alternate way
      id: "name",
      size: 150,
      filterFn: (row, id, filter) => {
        return t(varNameTranslation[row.original.var_name])
          .toLowerCase()
          .includes(filter.toLowerCase());
      },
      Cell: (cell) => (
        <VarNameCell cell={cell} varNameTranslation={varNameTranslation} />
      ),
    },
    {
      header: t("value"),
      accessorFn: (original) => original.knowledge,
      id: "value",
      size: 150,
      Cell: ({ row }) => {
        let { var_type, var_value, onChange } = row.original;

        if (row.original.var_name === "page_timeout") {
          var_type = "page";
        }

        return (
          <VarValueCell
            var_type={var_type}
            var_value={var_value}
            onChange={onChange}
            agentPages={agentPages}
          />
        );
      },
    },
  ]);

  useEffect(() => {
    setAgentVar(vars);
    if (vars) getAgentPages(vars[0].parentName);
  }, [vars]);

  const updateAgentConfig = (agentVar) => {
    const body = {
      config_update: true,
      agent_id: agentVar.parentId,
      agent_name: agentVar.parentName,
      var_name: agentVar.var_name,
      var_type: agentVar.var_type,
      var_value: agentVar.var_value,
    };
    put("/agents", body)
      .then(async () => {
        enqueueSnackbar(t("agents:agentVarUpdated"), {
          variant: "success",
        });
        getAgents();
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorVarUpdated"), {
          variant: "error",
        });
      });
    setOpenEditAgentVarModal(false);
  };

  const getAgentPages = async (agentName) => {
    await get("/flows?agent=" + agentName)
      .then((data) => {
        let tempData = data.nodes.sort((a, b) =>
          a.data.label.toLowerCase() > b.data.label.toLowerCase() ? 1 : -1,
        );
        setAgentPages(tempData);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <ThemeProvider theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
        <Typography
          variant="h6"
          sx={{ display: "flex", mb: "1rem", justifyContent: "center" }}>
          {t("agentVars")}
        </Typography>
        <MaterialReactTable
          localization={
            i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
          }
          columns={columns}
          data={agentVar}
          enableEditing
          enableColumnOrdering
          enablePagination={false}
          muiTableContainerProps={{
            sx: { maxHeight: "700px" },
          }}
          positionActionsColumn="last"
          editDisplayMode="modal"
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "left",
              },
              size: 50,
            },
          }}
          renderRowActions={({ row }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip
                arrow
                placement="left"
                title={
                  userPermissions
                    ? t("editVarButton")
                    : t("noPermissionTooltip")
                }>
                <span>
                  <IconButton
                    id="edit-agent-var"
                    disabled={!userPermissions}
                    onClick={() => {
                      setSelectedAgentVar(row.original);
                      setOpenEditAgentVarModal(true);
                    }}>
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}></MaterialReactTable>
        <EditAgentVarsModal
          selectedAgentVar={selectedAgentVar}
          open={openEditAgentVarModal}
          onSave={(agentVar) => updateAgentConfig(agentVar)}
          agentPages={agentPages}
          onClose={() => {
            setOpenEditAgentVarModal(false);
          }}></EditAgentVarsModal>
      </ThemeProvider>
    </div>
  );
};

// PropTypes for VarNameCell component
VarNameCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        var_name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  varNameTranslation: PropTypes.objectOf(PropTypes.string).isRequired,
};

// PropTypes for VarValueCell component
VarValueCell.propTypes = {
  var_type: PropTypes.string.isRequired,
  var_value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  onChange: PropTypes.func,
  agentPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  ),
};

// PropTypes for AgentVars component
AgentVars.propTypes = {
  vars: PropTypes.arrayOf(
    PropTypes.shape({
      var_name: PropTypes.string.isRequired,
      var_type: PropTypes.string.isRequired,
      var_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ]),
      parentName: PropTypes.string,
      parentId: PropTypes.string,
      onChange: PropTypes.func,
    }),
  ).isRequired,
  getAgents: PropTypes.func.isRequired,
  userPermissions: PropTypes.bool.isRequired,
};

export default AgentVars;
