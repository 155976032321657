import React, { useMemo } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import {
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";

const TableMapper = ({
  editing,
  edit,
  route,
  handleEditInput,
  errorMapped,
  tableRef,
}) => {

  const {t, i18n} = useTranslation("tableMapper");

  const columns = useMemo(
    () => [
      { accessorKey: "originalName", 
        header: t("name"),
      },
      { accessorKey: "mappedName", header: t("mappedName"), required: true },
    ],
    [editing, edit, route, i18n.language],
  );

  const data = editing ? edit?.responseMapping : route.responseMapping;

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnHeaders: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableFilters: false,
    enableSorting: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableRowActions: true,
    enableEditing: true,
    enableDelete: true,
    positionActionsColumn: "last",
    renderBottomToolbar: false,
    localization: i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex" }}>
        <Tooltip title={t("edit")} placement="left">
          <IconButton
            id="buttonEditMapper"
            disabled={!editing}
            onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("delete")} placement="left">
          <IconButton
            id="buttonDeleteMapper"
            color="error"
            disabled={!editing}
            onClick={() => handleEditInput(row.original, "routeMapperDelete")}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Button
          id="buttonAddMapper"
          variant="contained"
          color="primary"
          disabled={!editing}
          onClick={() => table.setCreatingRow(true)}>
          {t("addNewResponse")}
        </Button>
        {errorMapped && <Typography color="error">{errorMapped}</Typography>}
      </>
    ),
    onCreatingRowSave: ({ values }) => {
      handleEditInput(values, "routeMapperAdd");
    },
    onEditingRowSave: ({ values, row }) => {
      handleEditInput({ values, row }, "routeMapperEdit");
    },
  });

  tableRef.current = table;

  return (
    <Box sx={{ width: "100%" }}>
      <MaterialReactTable table={table}/>
    </Box>
  );
};

export default TableMapper;
