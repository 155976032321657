import React, { useRef, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { get } from "../../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function ChipInput({ ...props }) {
  const [inputValue, setInputValue] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const ref = useRef(null);
  const [editFlag, setEditFlag] = React.useState(false);
  const [oldTag, setOldTag] = React.useState("");
  const [isMaxLengthReached, setIsMaxLengthReached] = React.useState(false);
  const isReadOnly = props.readOnly === true;
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation("chipInput");

  useEffect(() => {
    if (props.value?.length > 0) {
      setTags(props.value);
    } else {
      setTags([]);
    }
  }, []);

  useEffect(() => {
    if (props.value?.length > 0) {
      setTags(props.value);
    } else {
      setTags([]);
    }
  }, [props.value]);

  async function handleKeyDown(event) {
    const key = props.newChipKeys ? props.newChipKeys : [";"];
    if (
      (key.includes(event.key) || key.includes(event.code)) &&
      inputValue !== "" &&
      !isMaxLengthReached
    ) {
      event.preventDefault();
      await addTag(event.target.value.trim());
    }

    let maxLengthReached = inputValue.length >= 10001;
    setIsMaxLengthReached(maxLengthReached);

    if (props.answerLengthError) {
      props.lengthError(isMaxLengthReached);
    }

    if (event.key === "Enter") {
      setInputValue(event.target.value + "\n");
    }

    if (inputValue.length && !inputValue.length && event.key === "Backspace") {
      setTags(inputValue.slice(0, inputValue.length - 1));
    }
  }

  async function addTag(value) {
    const newSelectedItem = [...tags];
    const duplicatedValues = newSelectedItem.indexOf(value);

    if (duplicatedValues != -1) {
      setInputValue("");
      if (editFlag) {
        setOldTag("");
        setEditFlag(false);
        setTags(newSelectedItem);
        props.updateProps(newSelectedItem);
      }
      return;
    }

    if (editFlag) {
      let index = tags.indexOf(oldTag);
      newSelectedItem.splice(index, 1, value);
    } else {
      newSelectedItem.push(value);
    }
    setOldTag("");
    setEditFlag(false);
    setTags(newSelectedItem);
    props.updateProps(newSelectedItem);
    setInputValue("");
    ref.current.value = "";

    if (props.getValidation?.get) {
      let question = newSelectedItem[newSelectedItem.length - 1];
      await getValidationQuestionIntent(
        props.getValidation.agentSelected,
        question,
      );
    }
  }

  const getValidationQuestionIntent = async (agentSelected, e) => {
    await get("/intents/validatequestion/" + agentSelected + "/" + e)
      .then(() => {})
      .catch((error) => {
        const errorMessage = error.response.data.join(", ");
        enqueueSnackbar(t("questionError", { errorMessage }), {
          variant: "warning",
        });
      });
  };

  function handleEdit(index) {
    if (isReadOnly) return;
    if (editFlag) {
      if (oldTag !== tags[index]) return;
      setInputValue("");
      setEditFlag(false);
      setOldTag("");
    }

    let maxLengthReached = inputValue.length >= 10001;
    setIsMaxLengthReached(maxLengthReached);

    if (props.answerLengthError) {
      props.lengthError(isMaxLengthReached);
    }

    if (inputValue !== "") return;
    setInputValue(tags[index]);
    setOldTag(tags[index]);
    setEditFlag(true);
  }

  function handleDelete(index) {
    if (editFlag) return;
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    props.updateProps(newTags);
  }

  function handleClear() {
    setTags([]);
    setInputValue("");
    props.updateProps([]);
  }

  const getChipStyles = (option, oldTag, fontColor) => ({
    color: fontColor || "inherit",
    backgroundColor: oldTag === option ? "chipEdit.normal" : "default",
    "&:hover": {
      backgroundColor: oldTag === option ? "chipEdit.hover" : "default",
    },
    "&.MuiChip-root.Mui-disabled": {
      opacity: 1,
    },
  });
  
  const getDeleteIcon = (option, oldTag, editFlag, fontColor) =>
    oldTag === option && editFlag ? (
      <EditIcon style={{ color: fontColor || "inherit", fontSize: "1rem" }} />
    ) : (
      <DeleteIcon style={{ color: fontColor || "inherit", fontSize: "1rem" }} />
    );
  
  const handleTypographyClick = (e, disabled) => {
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Autocomplete
      sx={props.sx}
      id={props.id}
      multiple
      options={[]}
      freeSolo
      onKeyDown={(e) => {
        handleKeyDown(e);
      }}
      disabled={props.disabled}
      value={tags}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event && event.type === "blur") {
          setInputValue("");
        } else if (reason === "clear") {
          handleClear();
        } else if (isReadOnly) {
          return;
        } else if (reason !== "reset") {
          setInputValue(value);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            sx={getChipStyles(option, oldTag, props.fontColor)}
            color={oldTag === option ? "primary" : "default"}
            variant={oldTag === option && editFlag ? "filled" : "outlined"}
            label={
              <Typography
                sx={{
                  display: "block",
                  whiteSpace: "pre-line",
                  ...(props.disabled && {
                    userSelect: "text",
                    cursor: "text",
                    pointerEvents: "auto",
                  }),
                }}
                onClick={(e) => handleTypographyClick(e, props.disabled)}
                onMouseDown={(e) => props.disabled && e.stopPropagation()}
              >
                {option}
              </Typography>
            }
            {...getTagProps({ index })}
            onClick={props.disabled ? undefined : () => handleEdit(index)}
            onDelete={props.disabled ? undefined : () => handleDelete(index)}
            deleteIcon={getDeleteIcon(option, oldTag, editFlag, props.fontColor)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          id={props.id}
          ref={ref}
          {...params}
          variant="filled"
          style={{ backgroundColor: "rgba(213, 213, 231, 0.2)" }}
          multiline
          label={props.label}
          placeholder={props.placeholder}
          value={inputValue}
          error={props.error}
          helperText={props.error}
          onPaste={async (e) => {
            e.preventDefault();
            if (isReadOnly) return;
            let text = e.clipboardData.getData("text/plain");
            let lines = text.split("\n");
            let newSelectedItem = [...tags];
            if (props.getValidation?.get) {
              await getValidationQuestionIntent(
                props.getValidation.agentSelected,
                lines,
              );
            }
            let duplicatedValues = [];
            lines.forEach((line) => {
              if (line.trim() === "") return;
              duplicatedValues = newSelectedItem.indexOf(line.trim());
              if (duplicatedValues != -1) return;
              newSelectedItem.push(line.trim());
            });
            setTags(newSelectedItem);
            props.updateProps(newSelectedItem);
          }}
          InputProps={{
            ...params.InputProps,
            readOnly: isReadOnly,
          }}
        />
      )}
    />
  );
}

ChipInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  updateProps: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  getValidation: PropTypes.shape({
    get: PropTypes.func,
    agentSelected: PropTypes.string,
  }),
  fontColor: PropTypes.string,
  disabled: PropTypes.bool,
  answerLengthError: PropTypes.bool,
  lengthError: PropTypes.func,
  newChipKeys: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  sx: PropTypes.object,
};
