import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Tooltip,
  FormControl,
  List,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Container, Draggable } from "@smooth-dnd/react";
import QuickRepliesCard from "./QuickRepliesCard";
import { useTranslation } from "react-i18next";
import "./QuickReplies.css";

export default function StaticQuickReplies({
  quickRepliesStatic,
  setQuickRepliesStatic,
  userPermissions,
  isAddDisabled,
  intents,
  errors,
  errorIndex,
}) {
  const { t } = useTranslation();
  const [filteredIntents, setFilteredIntents] = useState([]);
  const [dirtyFields, setDirtyFields] = useState({});

  const filterIntents = useCallback((text) => {
    const filtered = intents?.filter((intent) =>
      intent.name.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredIntents(filtered);
  }, [intents]);

  const handleAddQuickReply = useCallback((index) => {
    if (isAddDisabled) return;
    const newReplies = [...(quickRepliesStatic || [])];  
    if (newReplies.length < 10) {
      if (index !== undefined) {
        newReplies.splice(index + 1, 0, { value: null, label: null });
      } else {
        newReplies.push({ value: null, label: null });
      }
      setQuickRepliesStatic(newReplies);
    }
  }, [isAddDisabled, quickRepliesStatic, setQuickRepliesStatic]);

  const handleRemoveQuickReply = useCallback(() => {
    const newReplies = [...(quickRepliesStatic || [])];
    if (newReplies.length) {
      newReplies.pop();
      setQuickRepliesStatic(newReplies);
      setDirtyFields(prev => {
        const newDirty = { ...prev };
        delete newDirty[`label-${newReplies.length}`];
        delete newDirty[`value-${newReplies.length}`];
        return newDirty;
      });
    }
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const updateQuickRepliesLabel = useCallback((index, value) => {
    const newReplies = [...(quickRepliesStatic || [])];
    if (newReplies.length > index) {
      newReplies[index].label = value;
      setQuickRepliesStatic(newReplies);
      setDirtyFields(prev => ({
        ...prev,
        [`label-${index}`]: true,
      }));
    }
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const updateQuickRepliesValue = useCallback((index, value) => {
    const newReplies = [...(quickRepliesStatic || [])];
    filterIntents(value);

    if (newReplies.length > index) {
      const selectedIntent = filteredIntents.find(
        (intent) => intent.name === value
      );
      newReplies[index].value = selectedIntent ? selectedIntent.phrases[0] : value;
      setQuickRepliesStatic(newReplies);
      setDirtyFields(prev => ({
        ...prev,
        [`value-${index}`]: true,
      }));
    }
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const updateQuickRepliesDescription = useCallback((index, value) => {
    const newReplies = [...(quickRepliesStatic || [])]; 
    if (newReplies.length > index) {
      newReplies[index].description = value;
      setQuickRepliesStatic(newReplies);
      setDirtyFields(prev => ({
        ...prev,
        [`description-${index}`]: true,
      }));
    }
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const deleteQuickReply = useCallback((index) => {
    const newReplies = [...(quickRepliesStatic || [])];
    newReplies.splice(index, 1);
    setQuickRepliesStatic(newReplies);
    
    setDirtyFields(prev => {
      const newDirty = {};
      Object.entries(prev).forEach(([key, value]) => {
        const [field, idx] = key.split("-");
        const currentIndex = parseInt(idx);
        if (currentIndex < index) {
          newDirty[key] = value;
        } else if (currentIndex > index) {
          newDirty[`${field}-${currentIndex - 1}`] = value;
        }
      });
      return newDirty;
    });
    
    setTimeout(() => {
      setQuickRepliesStatic([...newReplies]);
    }, 0);
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const arrayMove = (array, removedIndex, addedIndex) => {
    if (addedIndex >= array.length) {
      let k = addedIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(addedIndex, 0, array.splice(removedIndex, 1)[0]);
    return array;
  };

  const onDrop = useCallback((removedIndex, addedIndex) => {
    const newReplies = [...(quickRepliesStatic || [])];
    const result = arrayMove(newReplies, removedIndex, addedIndex);
    setQuickRepliesStatic(result);
  }, [quickRepliesStatic, setQuickRepliesStatic]);

  const tooltipMessage = useMemo(() => {
    if (isAddDisabled) {
      return t("flowgraph:lengthValidation");
    } else if (!userPermissions) {
      return t("flowgraph:noPermissionTooltip");
    }
    return "";
  }, [isAddDisabled, userPermissions]);

  const isFieldDirty = useCallback((fieldName, index) => {
    return dirtyFields[`${fieldName}-${index}`];
  }, [dirtyFields]);
  

  return (
    <div>
      <div
        className="quickreply-buttons"
        style={{
          float: "right",
          display: "inline-block",
          marginTop: "7px",
        }}>
        <Tooltip title={tooltipMessage}>
          <span>
            <IconButton
              id="quick-reply-button-add"
              disabled={!userPermissions || isAddDisabled}
              sx={{ 
                backgroundColor: "#3A3EB5",
                "&:hover": {
                  backgroundColor: "#3A3EB5",
                },
                color: "#4e8cff"}}
              fontSize="inherit"
              onClick={() => handleAddQuickReply(quickRepliesStatic?.length ? quickRepliesStatic.length - 1 : undefined)}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
          <span>
            <IconButton
              id="quick-reply-button-remove"
              disabled={!userPermissions}
              sx={{ 
                backgroundColor: "#3A3EB5",
                "&:hover": {
                  backgroundColor: "#3A3EB5",
                },
                color: "#4e8cff"}}
              fontSize="inherit"
              onClick={handleRemoveQuickReply}>
              <RemoveIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <FormControl fullWidth>
        <List sx={{ width: "100%" }}>
          <Container
            sx={{ display: "flex", justifyContent: "center" }}
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: "cards-drop-preview",
            }}
            dragClass="card-ghost"
            dropClass="card-ghost-drop"
            onDrop={(event) => onDrop(event.removedIndex, event.addedIndex)}
          >
            {(quickRepliesStatic || []).map((quickReply, index) => (
              <Draggable key={quickReply.value || index}>
                <QuickRepliesCard
                  reply={quickReply}
                  index={index}
                  userPermissions={userPermissions}
                  errors={errors}
                  errorIndex={errorIndex}
                  updateQuickRepliesLabel={updateQuickRepliesLabel}
                  updateQuickRepliesValue={updateQuickRepliesValue}
                  updateQuickRepliesDescription={updateQuickRepliesDescription}
                  deleteQuickReply={deleteQuickReply}
                  handleAddQuickReply={handleAddQuickReply}
                  isAddDisabled={isAddDisabled}
                  filteredIntents={filteredIntents}
                  filterIntents={filterIntents}
                  tooltipMessage={tooltipMessage}
                  isFieldDirty={isFieldDirty}
                />
              </Draggable>
            ))}
          </Container>
        </List>
      </FormControl>
    </div>
  );
}

StaticQuickReplies.propTypes = {
  quickRepliesStatic: PropTypes.array.isRequired,
  setQuickRepliesStatic: PropTypes.func.isRequired,
  userPermissions: PropTypes.bool,
  isAddDisabled: PropTypes.bool,
  intents: PropTypes.array,
  errors: PropTypes.object,
  errorIndex: PropTypes.object,
};