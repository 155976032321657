import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { get, post, put, remove } from "../../shared/http/httpService";
import { useSnackbar } from "notistack";
import EditModal from "./EditModal.component";
import { DeleteRowModal } from "../../shared/helper/tableHelper";

// Clonar el tema y eliminar la propiedad shadows
const modifiedLightTheme = { ...tableLightTheme, shadows: [] };
const modifiedDarkTheme = { ...tableDarkTheme, shadows: [] };

const CampaignsTable = ({ providersData, userPermissions, clientSelected }) => {
  const { t, i18n } = useTranslation("transfersTable");
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const theme = useSelector((state) => state.theme);
  const [typeModal, setTypeModal] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: t("nameCampaign"),
        accessorKey: "name",
      },
      {
        header: t("idCampaign"),
        accessorKey: "id",
      },
      {
        header: t("providerCampaign"),
        accessorKey: "provider",
        Cell: ({ cell }) => {
          return t(cell.getValue());
        },
      },
    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    enableDelete: true,
    editDisplayMode: "modal",
    enableEditing: true,
    initialState: {
      density: "compact",
    },
    mrtTheme: () => ({
      baseBackgroundColor: theme.darkTheme
        ? modifiedLightTheme.palette.background.default
        : modifiedDarkTheme.palette.background.default,
    }),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          arrow
          placement="left"
          title={userPermissions ? t("editButton") : t("noPermissionTooltip")}>
          <span>
            <IconButton
              id="edit-icon-transfer"
              onClick={() => {
                setEditModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          arrow
          placement="right"
          title={
            userPermissions ? t("deleteButton") : t("noPermissionTooltip")
          }>
          <span>
            <IconButton
              id="delete-icon-transfer"
              sx={{ color: "deleteIcon.color" }}
              onClick={() => {
                setDeleteModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}>
          <div>
            <Typography variant="h4">{t("tittleCampaigns")}</Typography>
            <Typography variant="subtitle3">
              {t("subTittleCampaign")}
            </Typography>
          </div>
          <Tooltip
            arrow
            placement="right"
            title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <span>
              <Button
                className="mrt-create-new-account-button"
                id="new-button-campaign"
                onClick={() => {
                  setTypeModal("new");
                  setEditModalOpen(true);
                }}
                disabled={!userPermissions}
                variant="contained">
                {t("newCampaigns")}
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    ),
  });

  const getCampaigns = () => {
    get("/campaigns", { clientId: clientSelected })
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  const newCampaigns = useCallback((campaign) => {
    campaign.clientId = clientSelected;
    post("/campaigns", campaign)
      .then(() => {
        getCampaigns();
        enqueueSnackbar(t("campaignCreated"), {
          variant: "success",
        });
        setTypeModal("");
      })
      .catch(() => {
        enqueueSnackbar(t("errorCreatingCampaign"), {
          variant: "error",
        });
      });
  }, [clientSelected, getCampaigns, enqueueSnackbar, t]);

  const updateCampaign = useCallback((campaign) => {
    campaign.clientId = clientSelected;
    put(`/campaigns/${campaign.originalId}`, campaign)
      .then(() => {
        getCampaigns();
        enqueueSnackbar(t("campaignUpdated"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("errorUpdatingCampaign"), {
          variant: "error",
        });
      });
  }, [clientSelected, getCampaigns, enqueueSnackbar, t]);

  const deleteCampaign = useCallback((campaign) => {
    campaign.clientId = clientSelected;
    remove(`/campaigns/${campaign._id}`, campaign)
      .then(() => {
        getCampaigns();
        enqueueSnackbar(t("campaignDeleted"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("errorDeletingCampaign"), {
          variant: "error",
        });
      });
  }, [clientSelected, getCampaigns, enqueueSnackbar, t]);

  const validateDuplicateName = useCallback((name, _id) => {
    const alguno = data.some(
      (campaign) => campaign.name === name && campaign._id !== _id,
    );
    return alguno;
  }, [data]);

  useEffect(() => {
    if (clientSelected !== null) {
      getCampaigns();
    }
  }, [clientSelected]);

  return (
    <div>
      <ThemeProvider
        theme={theme.darkTheme ? modifiedLightTheme : modifiedDarkTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        providersData={providersData}
        data={{
          name: editRow?.original?.name,
          id: editRow?.original?.id,
          provider: editRow?.original?.provider,
          _id: editRow?.original?._id,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newCampaigns(values);
            setEditModalOpen(false);
          } else {
            updateCampaign(values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
        validateDuplicateName={validateDuplicateName}
      />
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteCampaign(editRow?.original);
          setDeleteModalOpen(false);
        }}
        title={t("tittleDeleteModalCampaign", {
          name: editRow?.original?.name,
        })}
      />
    </div>
  );
};

CampaignsTable.propTypes = {
  providersData: PropTypes.array.isRequired,
  userPermissions: PropTypes.bool.isRequired,
  clientSelected: PropTypes.string,
};

export default CampaignsTable;