/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FormControlLabel,
  MenuItem,
  Select,
  Checkbox,
  InputLabel,
  IconButton,
} from "@mui/material";
import DateRange from "../../../../shared/components/DateRange";
import TimeRange from "../../../../shared/components/TimeRange";
import { DayPicker } from "../../../../shared/components/DayPicker";
import { HolidayPicker } from "../../../../shared/components/HolidayPicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import { withinRange } from "../../../../shared/helper/validations";
import dayjs from "dayjs";
import PropTypes from "prop-types"; // Importar PropTypes

export default function EdgeDateConditions({ ...props }) {
  const { t } = useTranslation("edge");
  const [dateConditions, setDateConditions] = useState([]);
  const [valid, setValid] = useState(true);
  const dateOperations = [
    {
      name: t("dateTime"),
      value: "time",
    },
    {
      name: t("dateDate"),
      value: "date",
    },
    {
      name: t("dateDayName"),
      value: "day",
    },
    {
      name: t("dateHoliday"),
      value: "holiday",
    },
    {
      name: t("dateSchedule"),
      value: "schedule",
    },
  ];

  useEffect(() => {
    if (props.dateConditions) {
      setDateConditions(props.dateConditions);
    }
  }, [props.dateConditions]);

  const validateAndUpdate = (dateConditions) => {
    let isValid =
      withinRange(dateConditions) && validateEmptyConditions(dateConditions);
    let validAux = valid !== undefined ? valid && isValid : isValid;
    props.dateConditionsUpdate(dateConditions, validAux);
  };

  const handleAddCondition = () => {
    let current = [...dateConditions];
    current.push({
      op: "time",
      value: {
        startTime: dayjs().startOf("day").format("HH:mm:ss"),
        endTime: dayjs().endOf("day").format("HH:mm:ss"),
      },
    });
    setDateConditions(current);
    validateAndUpdate(current);
  };

  const handleRemoveCondition = () => {
    if (dateConditions.length > 1) {
      let current = dateConditions.slice(0, -1);
      setDateConditions(current);
      validateAndUpdate(current);
    }
  };

  const updateDateOp = (e, index) => {
    let current = [...dateConditions];
    current[index].op = e.target.value;
    current[index].reverse = false;
    if (e.target.value === "time") {
      current[index].value = {
        startTime: dayjs().startOf("day").format("HH:mm:ss"),
        endTime: dayjs().endOf("day").format("HH:mm:ss"),
      };
    } else if (e.target.value === "date") {
      current[index].value = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
    } else if (
      e.target.value === "holiday" ||
      e.target.value === "schedule" ||
      e.target.value === "day"
    ) {
      current[index].value = [];
      if (e.target.value === "schedule") {
        validateSchedule();
        setValid(true);
      }
    } else {
      delete current.value;
    }
    setDateConditions(current);
    validateAndUpdate(current);
  };

  const updateDateValue = (e, index) => {
    let current = [...dateConditions];
    current[index].value = e;
    if (current[index].op === "holidays") {
      setValid(true);
    } else {
      setValid(e.valid);
    }
    setDateConditions(current);
    validateAndUpdate(current);
  };

  const updateDateCheck = async (e, index) => {
    let current = [...dateConditions];
    current[index].reverse = e;
    setDateConditions(current);
    validateAndUpdate(current);
  };

  const validateSchedule = () => {
    // Checks if there are existing "Schedule" transitions.
    let edgesToCheck = props.edges.filter(
      (condition) => condition.source === props.source,
    );
    let conditions = [...dateConditions];
    let conditionIndex = conditions.findIndex(
      (condition) => condition.op === "schedule",
    );

    if (conditionIndex === -1) {
      return;
    }

    edgesToCheck.forEach((edge) => {
      edge.data.transitions.forEach((transition) => {
        if (transition.label.includes("Out of Schedule")) {
          conditions[conditionIndex].reverse = false;
          conditions[conditionIndex].disabled = true;
        } else if (transition.label.includes("Schedule")) {
          conditions[conditionIndex].reverse = true;
          conditions[conditionIndex].disabled = true;
        } else {
          conditions[conditionIndex].disabled = false;
        }
      });
    });

    setDateConditions(conditions);
    validateAndUpdate(conditions);
  };

  const validateEmptyConditions = (conditions) => {
    return !conditions.some(
      (condition) =>
        (condition.op === "holiday" && condition.value.length === 0) ||
        (condition.op === "day" &&
          !condition.value.some((day) => day !== "")) ||
        (condition.op === "date" &&
          (condition.value.startDate === null ||
            condition.value.endDate === null)),
    );
  };

  const renderConditionComponent = (condition, index) => {
    switch (condition.op) {
      case "date":
        return (
          <Tooltip
            title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
            <span>
              <DateRange
                disabled={!props.userPermissions}
                startDate={dateConditions[index].value.startDate}
                endDate={dateConditions[index].value.endDate}
                allowFuture={true}
                onDateSelected={(e) => updateDateValue(e, index)}
              />
            </span>
          </Tooltip>
        );
      case "day":
        return (
          <Tooltip
            title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
            <span>
              <DayPicker
                disabled={!props.userPermissions}
                days={dateConditions[index].value}
                daySelected={(e) => updateDateValue(e, index)}
              />
            </span>
          </Tooltip>
        );
      case "holiday":
        return (
          <Tooltip
            title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
            <span>
              <HolidayPicker
                disabled={!props.userPermissions}
                selected={dateConditions[index].value}
                holidaySelected={(e) => updateDateValue(e, index)}
                reverse={dateConditions[index].reverse}
                onChecked={(e) => updateDateCheck(e, index)}
              />
            </span>
          </Tooltip>
        );
      case "time":
        return (
          <Tooltip
            title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
            <span>
              <TimeRange
                disabled={!props.userPermissions}
                startTime={dateConditions[index].value.startTime}
                endTime={dateConditions[index].value.endTime}
                onTimeSelected={(e) => updateDateValue(e, index)}
              />
            </span>
          </Tooltip>
        );
      case "schedule": {
        return (
          <FormControlLabel
            label={t("dateOutSchedule")}
            control={
              <Tooltip
                title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() =>
                      updateDateCheck(
                        condition.reverse ? !condition.reverse : true,
                        index,
                      )
                    }
                    checked={condition.reverse}
                    disabled={condition.disabled || !props.userPermissions}
                  />
                </span>
              </Tooltip>
            }
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      {dateConditions.map((condition, index) => (
        <div key={index} className="date">
          <div className="condition-header">
            <h3 className="condition-title">
              {t("dateCondition")} {index + 1}
            </h3>
            {index === 0 && (
              <div className="condition-buttons">
                <Tooltip
                  title={
                    !props.userPermissions
                      ? t("noPermissionTooltip")
                      : 'The conditions are "AND" type.'
                  }>
                  <span>
                    <IconButton
                      id="add-condition"
                      disabled={!props.userPermissions}
                      onClick={handleAddCondition}>
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    !props.userPermissions ? t("noPermissionTooltip") : ""
                  }>
                  <span>
                    <IconButton
                      id="remove-condition"
                      disabled={!props.userPermissions}
                      onClick={handleRemoveCondition}>
                      <RemoveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
          <InputLabel shrink>{t("dateOperator")}</InputLabel>
          <Tooltip
            title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
            <Select
              id="date-operator"
              className="date-operator"
              fullWidth
              disabled={!props.userPermissions}
              defaultValue={
                condition.op ? condition.op : dateOperations[0].value
              }
              sx={{ marginBottom: "20px" }}
              onChange={(e) => updateDateOp(e, index)}>
              {dateOperations.map((condition, index) => (
                <MenuItem
                  name={condition.name}
                  value={condition.value}
                  key={index}>
                  {condition.name}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
          {renderConditionComponent(condition, index)}
        </div>
      ))}
    </div>
  );
}

EdgeDateConditions.propTypes = {
  dateConditions: PropTypes.arrayOf(
    PropTypes.shape({
      op: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
          startDate: PropTypes.instanceOf(Date),
          endDate: PropTypes.instanceOf(Date),
        }),
      ]).isRequired,
      reverse: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      data: PropTypes.shape({
        transitions: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  source: PropTypes.string.isRequired,
  userPermissions: PropTypes.bool.isRequired,
  dateConditionsUpdate: PropTypes.func.isRequired,
};
