import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, TextField, Card, Typography, ListItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export default function QuickRepliesCard({
  reply,
  index,
  userPermissions,
  errors,
  errorIndex,
  updateQuickRepliesLabel,
  updateQuickRepliesValue,
  updateQuickRepliesDescription,
  deleteQuickReply,
  handleAddQuickReply,
  isAddDisabled,
  filteredIntents,
  filterIntents,
  tooltipMessage,
  isFieldDirty,
}) {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(reply.value || "");

  useEffect(() => {
    setLocalValue(reply.value || "");
  }, [reply.value]);

  const handleFilter = (e) => {
    if (e.target.value === "") {
      filterIntents("");
    }
  };

  const truncatedPhrases = useMemo(() => {
    return (intent) => {
      let truncatedPhrases = "";
      let isTruncated = false;

      for (const phrase of intent.phrases) {
        const newLength = (truncatedPhrases + phrase).length;
        if (newLength <= 50) {
          truncatedPhrases += phrase + ", ";
        } else {
          truncatedPhrases = truncatedPhrases.slice(0, -2) + " ...";
          isTruncated = true;
          break;
        }
      }

      if (!isTruncated) {
        truncatedPhrases = truncatedPhrases.slice(0, -2) + ".";
      }
      return truncatedPhrases.length > 0 ? truncatedPhrases : "";
    };
  }, []);
  
  const handleValueChange = (e) => {
    setLocalValue(e.target.value);
  };

  const handleValueBlur = () => {
    updateQuickRepliesValue(index, localValue);
  };

  const getLabelError = () => {
    if (isFieldDirty("label", index)) {
      if (!reply.label || reply.label.trim() === "") {
        return t("flowgraph:errorTitleIsRequired");
      }
      if (reply.label.length > 20) {
        return t("flowgraph:errorTitleTooLarge");
      }
      if (errors?.quickRepliesLabel && errorIndex?.quickRepliesLabel?.includes(index)) {
        return errors.quickRepliesLabel;
      }
    }
    return "";
  };

  const getLabelErrorStatus = () => {
    if (isFieldDirty("label", index)) {
      return !reply.label || 
             reply.label.trim() === "" || 
             reply.label.length > 20 ||
             (errors?.quickRepliesLabel && errorIndex?.quickRepliesLabel?.includes(index));
    }
    return false;
  };

  const getValueError = () => {
    if (isFieldDirty("value", index)) {
      if (!localValue || localValue.trim() === "") {
        return t("flowgraph:errorPlayloadIsRequired");
      }
      if (errors?.quickRepliesValue && errorIndex?.quickRepliesValue?.includes(index)) {
        return errors.quickRepliesValue; 
      }
    }
    return "";
  };

  const getValueErrorStatus = () => {
    if (isFieldDirty("value", index)) {
      return !localValue || localValue.trim() === "" || 
             (errors?.quickRepliesValue && errorIndex?.quickRepliesValue?.includes(index));
    }
    return false;
  };

  const getDescriptionError = () => {
    if (isFieldDirty("description", index)) {
      if (reply.description && reply.description.length > 72) {
        return t("flowgraph:errorDescriptionTooLarge");
      }
    }
    return "";
  };

  const getDescriptionErrorStatus = () => {
    if (isFieldDirty("description", index)) {
      return reply.description && reply.description.length > 72;
    }
    return false;
  };

  return (
    <Card
      id={`quick-reply-card-${index}`} 
      sx={{
        width: "95%",
        marginTop: "5px", 
        marginBottom: "5px", 
        marginLeft: "2.5%",
        borderRadius: "15px",
        boxShadow: "6px 6px 6px 0px rgba(0,0,0,0.4)",
        backgroundColor: "cardQuickReply.background",
        border:
          errorIndex.quickRepliesValue &&
          (errorIndex.quickRepliesValue.includes(index) ||
            errorIndex.quickRepliesLabel.includes(index) ||
            errorIndex.quickRepliesLabelLong.includes(index) ||
            errorIndex.quickRepliesDescriptionLong.includes(index))
            ? "2px solid #D62828"
            : "",
      }}
    >
      <Typography sx={{ marginLeft: "5%", marginTop: "1%" }}>
        {t("flowgraph:quickRepliesCardTitle")} {index + 1}
      </Typography>
      <ListItem
        key={index}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "5%" }}>
          <IconButton
            className="drag-handle"
            size="large"
            sx={{ 
              backgroundColor: "#3A3EB5",
              "&:hover": {
                backgroundColor: "#3A3EB5",
              },
              color: "#4e8cff"}}
          >
            <DragHandleIcon />
          </IconButton>
        </div>
        <div style={{ width: "75%",  marginTop: "10px", marginBottom: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between"  }}>
            <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
              <TextField
                id="quickreply-title"
                label={t("flowgraph:title")}
                variant="standard"
                disabled={!userPermissions}
                value={reply.label || ""}
                fullWidth
                sx={{ marginRight: "5%"}}
                className="transition-message"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => updateQuickRepliesLabel(index, e.target.value)}
                error={getLabelErrorStatus()}
                helperText={getLabelError()}
              />
            </Tooltip>
            <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
              <TextField
                id="quick-reply-payload"
                label={t("flowgraph:payload")}
                variant="standard"
                className="transition-message"
                disabled={!userPermissions}
                value={localValue}
                fullWidth
                onChange={handleValueChange}
                onBlur={handleValueBlur}
                InputLabelProps={{ shrink: true }}
                onClick={(e) => handleFilter(e)}
                error={getValueErrorStatus()}
                helperText={getValueError()}
                InputProps={{
                  inputProps: {
                    autoComplete: "off",
                    list: `suggestions-${index}`, 
                  },
                }}
              />
            </Tooltip>
            {filteredIntents.length > 0 && (
              <datalist id={`suggestions-${index}`}>
                {filteredIntents.map((intent) => (
                  <option key={intent._id} value={intent.name}>
                    {intent.phrases && intent.phrases.length > 1
                      ? truncatedPhrases(intent)
                      : intent.phrases[0] + "."}
                  </option>
                ))}
              </datalist>
            )}
          </div>
          <Tooltip title={!userPermissions ? t("flowgraph:noPermissionTooltip") : ""}>
            <TextField
              id="quick-reply-description"
              label={t("flowgraph:description")}
              variant="standard"
              disabled={!userPermissions}
              className="transition-message"
              value={reply.description || ""}
              fullWidth
              onChange={(e) => updateQuickRepliesDescription(index, e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={getDescriptionErrorStatus()}
              helperText={getDescriptionError()}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title={tooltipMessage}>
            <span>
              <IconButton
                disabled={!userPermissions || isAddDisabled}
                edge="end"
                aria-label={t("flowgraph:add")}
                sx={{ 
                  backgroundColor: "#3A3EB5",
                  "&:hover": {
                    backgroundColor: "#3A3EB5",
                  },
                  color: "#4e8cff"}}
                onClick={() => handleAddQuickReply(index)}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !userPermissions
                ? t("flowgraph:noPermissionTooltip")
                : ""
            }>
            <span>
              <IconButton
                disabled={!userPermissions}
                edge="end"
                aria-label={t("flowgraph:delete")}
                sx={{ 
                  backgroundColor: "#3A3EB5",
                  "&:hover": {
                    backgroundColor: "#3A3EB5",
                  },
                  color: "deleteIcon.color"}}
               
                onClick={() => deleteQuickReply(index)}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </ListItem>
    </Card>
  );
}

QuickRepliesCard.propTypes = {
  reply: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  userPermissions: PropTypes.bool,
  errors: PropTypes.object,
  errorIndex: PropTypes.object,
  updateQuickRepliesLabel: PropTypes.func.isRequired,
  updateQuickRepliesValue: PropTypes.func.isRequired,
  updateQuickRepliesDescription: PropTypes.func.isRequired,
  deleteQuickReply: PropTypes.func.isRequired,
  handleAddQuickReply: PropTypes.func.isRequired,
  isAddDisabled: PropTypes.bool,
  filteredIntents: PropTypes.array,
  filterIntents: PropTypes.func.isRequired,
  tooltipMessage: PropTypes.string,
  isFieldDirty: PropTypes.func.isRequired,
};
