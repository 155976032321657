import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useSnackbar } from "notistack";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { get, post, remove } from "../shared/http/httpService";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import EditModal from "./EditModal.component";

const FormData = require("form-data");

const AiTraining = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("aiTraining");
  const [data, setData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useSelector((state) => state.theme);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "aiTraining" && permission.fullAccess !== true,
    );
    setUserPermissions(hasPermissions);
  };

  const client = useSelector((state) => state.client);
 
  useEffect(() => {
    getUserPermisions();
    getFinnetunningJobs();
  }, [loading, client.name]);

  const columns = useMemo(
    () => [
      {
        header: t("aiTraining:name"),
        accessorKey: "user_provided_suffix",
        size: 150,
        enableEditing: false,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        header: t("aiTraining:id"),
        accessorKey: "id",
        size: 150,
      },
      {
        header: t("aiTraining:model"),
        accessorKey: "model",
        size: 150,
      },
      {
        header: t("aiTraining:status"),
        accessorKey: "status",
        size: 150,
        Cell: ({ row }) => {
          const status = row.original.status;
          return t(`aiTraining:${status}`);
        },
      },
      
    ],
    [data, i18n.language],
  );

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const getFinnetunningJobs = () => {
    get("/aiTraining/finetunningJobs")
      .then((response) => {
        setData(response.jobs);
        setModels(response.models);
      })
      .catch(() => {
        enqueueSnackbar(t("aiTraining:errorGettingFinetunningJobs"), {
          variant: "error",
        });
      });
  };

  const finetunneModel = (newData) => {
    const name = newData.name;
    const model = newData.model;
    const train_file = newData.file;

    let formData = new FormData();
    formData.append("new_model_name", name);
    formData.append("model_to_finetunne", model);
    formData.append("train_file", train_file);

    let headers = {};
    headers["Content-type"] = "multipart/form-data";

    let error = "Error";

    post("/aiTraining/finetunneModel", formData, headers)
      .then(() => {
        enqueueSnackbar(t("aiTraining:trainingCreated"), {
          variant: "success",
        });
        setTimeout(() => {
          getFinnetunningJobs();
          setLoading(!loading);
        }, 700);
      })
      .catch((e) => {
        error = `Error: ${e.message}`;
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const cancelFinetunningJob = (row) => {
    const id = row.original.id;
    let error = "Error";
    
    remove(`/aiTraining/cancelJob?job_id=${id}`)
      .then(() => {
        enqueueSnackbar(t("aiTraining:jobCancelled"), {
          variant: "success",
        });
        setTimeout(() => {
          setDeleteModalOpen(false);
          getFinnetunningJobs();
          setLoading(!loading);
        }, 700);
      })
      .catch((e) => {
        error = `Error: ${e.message}`;
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleSave = (newData) => {
    finetunneModel(newData);
    setEditModalOpen(false);
  };

  return (
    <React.Fragment>
      <Typography variant="h4" sx={{ mb: "1rem" }}>
        {t("aiTraining:aiTraining")}
      </Typography>
      <ThemeProvider theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
        <MaterialReactTable
          localization={
            i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
          }
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 50,
            },
          }}
          positionActionsColumn="last"
          columns={columns}
          data={data}
          enableRowActions
          enableColumnOrdering
          enableGlobalFilter={false}
          enableEditing
          renderRowActions={({ row }) => (
            <Box
              sx={{ justifyContent: "center", gap: "1rem", display: "flex" }}>
              {row.original.status !== "cancelled" && 
               row.original.status !== "succeeded" && 
               row.original.status !== "failed" && (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !userPermissions
                      ? t("aiTraining:deleteButton")
                      : t("aiTraining:noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="mrt-delete-row-button"
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={userPermissions}>
                      <CancelIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          )}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <Tooltip arrow title={t("aiTraining:refreshData")}>
                <IconButton
                  id="mrt-refresh-data-button"
                  onClick={() => {
                    getFinnetunningJobs();
                  }}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Tooltip
                arrow
                placement="right"
                title={!userPermissions ? "" : t("aiTraining:noPermissionTooltip")}>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      openEditModal();
                    }}
                    disabled={userPermissions}
                    variant="contained">
                    {t("aiTraining:trainModel")}
                  </Button>
                </span>
              </Tooltip>
            </div>
          )}
        />
      </ThemeProvider>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          cancelFinetunningJob(editRow);
        }}
        title={t("aiTraining:tittleDeleteModal")}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={handleSave}
        models={models}
      />
    </React.Fragment>
  );
};

export default AiTraining;
