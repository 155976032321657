// 1. External library imports
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Background,
  getIncomers,
  getOutgoers,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import dagre from "@dagrejs/dagre";

// 2. Internal module imports
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { get, post, remove } from "../shared/http/httpService";
import { newObjectId } from "../shared/helper/validations";
import { setHasNoSaveChanges } from "../shared/redux/clientSlice";

// 3. Component imports
import Bar from "./Components/BarComponents/Bar.component";
import CustomEdge from "./Components/EdgeComponents/CustomEdge.component";
import "./Flowgraph-V2.component.css";
import NodeModal from "../Flowgraph/Components/nodeModal.component";
import EdgeModalTabsPanel from "./Components/EdgeComponents/EdgeModalTabsPanel.component";
import TracesHistory from "../shared/components/CardTraceFlow/TracesHistory.component";

const colors = {
  default: "#ff4000",
  start: "#0050ff",
  quickreplies: "#ff0073",
  transfer: "#f87171",
  other: "#a845d0",
  endpoint: "#007bff",
};

// dagre layout
const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
const nodeWidth = 25;
const nodeHeight = 25;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 100,
    ranksep: 300,
    edgesep: 100,
    ranker: "longest-path",
    acyclicer: "greedy",
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  // Buscar nodo "start"
  const startNode = nodes.find((node) => node.id === "start");
  let startPosition = null;

  if (startNode) {
    const dagreStartNode = dagreGraph.node(startNode.id);
    if (dagreStartNode) {
      startPosition = { x: dagreStartNode.x, y: dagreStartNode.y };
    }
  }

  // Calcular centro si no hay "start"
  if (!startPosition && nodes.length > 0) {
    const positions = nodes.map((node) => dagreGraph.node(node.id));
    const minX = Math.min(...positions.map((p) => p.x));
    const maxX = Math.max(...positions.map((p) => p.x));
    const minY = Math.min(...positions.map((p) => p.y));
    const maxY = Math.max(...positions.map((p) => p.y));

    startPosition = { x: (minX + maxX) / 2, y: (minY + maxY) / 2 };
  }

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return { nodes: newNodes, edges, startPosition };
};

export default function FlowgraphV2() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [agentSelected, setAgentSelected] = useState("");
  const [agentNames, setAgentNames] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [searchCoords, setSearchCoords] = useState({ x: 0, y: 0 });
  const [editEdge, setEditEdge] = useState({ data: {} });
  const [editNode, setEditNode] = useState(null);
  const [openNodeModal, setOpenNodeModal] = useState(false);
  const [openEdgeModal, setOpenEdgeModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [highlightedEdges, setHighlightedEdges] = useState([]);
  const [highlightedNodes, setHighlightedNodes] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [endpoints, setEndpoints] = useState([]);
  const [previous, setPrevious] = useState({});
  const [pagesLean, setPagesLean] = useState([]);
  const [intents, setIntents] = useState([]);
  const [traces, setTraces] = useState([]);
  const [intentsqr, setIntentsqr] = useState([]);
  const [edgeSelectedTab, setEdgeSelectedTab] = useState(0);
  const [deleteElements, setDeleteElements] = useState({
    pages: [],
    edges: [],
  });
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const dispatch = useDispatch();
  const [isFromPublish, setIsFromPublish] = useState(false);

  const hasSelectedNode = !!selectedNode;
  const orderPriority = ["condition", "intent", "entity", "date", "media", "direct"];

  let client = useSelector((state) => state.client);
  const prevClientName = useRef(client.display_name);

  const edgeTypes = useMemo(
    () => ({
      smoothstep: (props) => (
        <CustomEdge
          {...props}
          onEdgeSelectedTab={(index) => setEdgeSelectedTab(index)}
          highlightedEdges={highlightedEdges || []}
          hasSelectedNode={hasSelectedNode}
        />
      ),
      custom: (props) => (
        <CustomEdge
          {...props}
          highlightedEdges={highlightedEdges || []}
          hasSelectedNode={hasSelectedNode}
        />
      ),
    }),
    [highlightedEdges, selectedNode],
  );

  const zoomIn = useCallback(() => {
    reactFlowInstance.zoomIn({ duration: 500 });
  }, [reactFlowInstance]);

  const zoomOut = useCallback(() => {
    reactFlowInstance.zoomOut({ duration: 500 });
  }, [reactFlowInstance]);

  useEffect(() => {
    dispatch(setHasNoSaveChanges(isSaveChanges));
  }, [isSaveChanges]);

  useEffect(() => {
    if (client.display_name !== prevClientName.current) {
      prevClientName.current = client.display_name;
      getAgentNames();
      getEndpoints();
      setIsSaveChanges(false);
    }
  }, [client.display_name]);

  useEffect(() => {
    if (agentSelected !== "") {
      getPages();
      getAllPages();
      getIntents();
      getTraces();
      getUserPermisions();
    }
  }, [agentSelected]);

  const getAgentNames = () => {
    get("/essentials/data/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(
          data.agents,
          "display_name",
        );
        let default_agent = defaultAgent(newData);
        //TODO: get agent config
        //let agentConfig = [];
        //  newData.forEach((agent) => {
        //    if (agent.name === default_agent.name) {
        //      agentConfig = agent.vars;
        //    }
        //  });
        setAgentNames(newData);
        setAgentSelected(default_agent.name);
        //setAgentConfig(agentConfig);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const baseStyle = useMemo(
    () => ({
      background: colors.default,
      borderRadius: 10,
      fontSize: 15,
      color: "white",
      fontFamily: "Roboto",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.6)",
    }),
    [],
  );

  const hasQuickReplies = (node) =>
    (node.data?.quickReplies && node.data?.quickReplies.length > 0) ||
    (node.data?.quickRepliesDynamic &&
      node.data?.quickRepliesDynamic.length > 0);

  const customNodeStyles = useCallback(
    (node) => {
      let style = { ...baseStyle };
      let type = node.type;

      if (hasQuickReplies(node)) {
        style.background = colors.quickreplies;
      }

      if (node.data?.core_transfer_page) {
        style.background = colors.transfer;
      } else if (node.data?.transferToAgent?.agent) {
        style.background = colors.other;
        type = "output";
      } else if (node.node_type === "input") {
        style.background = colors.start;
        type = "input";
      } else if (node.data?.label === "End Session") {
        style.background = colors.start;
        type = "output";
      } else if (node.data?.endpoint) {
        style.background = colors.endpoint;
      } else {
        type = "custom";
      }

      if (
        selectedNode &&
        (node.id === selectedNode.id || highlightedNodes.includes(node.id))
      ) {
        style.border = "2px solid #75AADB";
        style.opacity = 1;
      } else if (selectedNode) {
        style.opacity = 0.2;
      }

      return { style, type };
    },
    [baseStyle, selectedNode, highlightedNodes],
  );

  const handleNodeClick = useCallback(
    (event, node) => {
      const incomingConnections = getIncomers(node, nodes, edges);
      const outgoingConnections = getOutgoers(node, nodes, edges);

      setSelectedNode(node);
      setHighlightedNodes(
        [...incomingConnections, ...outgoingConnections].map((n) => n.id),
      );
      setHighlightedEdges(
        edges
          .filter((edge) => edge.source === node.id || edge.target === node.id)
          .map((edge) => edge.id),
      );
    },
    [nodes, edges],
  );

  const handleEdgeClick = useCallback((event, edge) => {
    if (event.detail === 2) {
      return;
    }
    setSelectedNode(null);

    setHighlightedNodes([]);
    setHighlightedEdges([]);
    setHighlightedEdges([edge.id]);
  }, []);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((n) => {
        const styleTemp = customNodeStyles(n);
        if (JSON.stringify(n.style) !== JSON.stringify(styleTemp)) {
          return {
            ...n,
            style: styleTemp.style,
          };
        }
        return n;
      }),
    );
  }, [selectedNode, highlightedNodes, customNodeStyles]);

  const handlePaneClick = useCallback(() => {
    setSelectedNode(null);
    setHighlightedNodes([]);
    setHighlightedEdges([]);
  }, []);

  //funcion para nombre del nuevo nodo si ya existe new node
  const getNewNodeName = (nodes) => {
    let baseName = "New node";
    let maxNumber = 0;

    nodes.forEach((node) => {
      const match = node.data.label.match(/^New node (\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        if (number > maxNumber) {
          maxNumber = number;
        }
      }
    });

    return `${baseName} ${maxNumber + 1}`;
  };
  const orderTransitions = (transitions) => {
    return transitions.sort((a, b) => {
      return orderPriority.indexOf(a.data.type) - orderPriority.indexOf(b.data.type);
    });
  };

  const redifineEdge = (edge) => {
    const redifinedEdge = {
      id: edge.id,
      label: edge.label,
      source: edge.source,
      target: edge.target,
      type: edge.type,
      animated: edge.animated,
      labelStyle: edge.labelStyle,
      labelBgStyle: edge.labelBgStyle,
      style: edge.style,
      markerEnd: edge.markerEnd,
      data: {
        transitions: edge.data.transitions,
        isCircular: edge.data.isCircular,
        labelX: edge.data.labelX,
        labelY: edge.data.labelY,
      },
    };
    return redifinedEdge;
  };

  const getPages = useCallback(() => {
    get("/flows?agent=" + agentSelected).then(async (data) => {
      let newNodes = [];
      data.nodes.forEach((node) => {
        //if input node, then set coords
        if (node.node_type === "input") {
          setSearchCoords({ x: node.position.x, y: node.position.y });
        }

        let styleTmp = customNodeStyles(node);
        newNodes.push({
          ...node,
          style: styleTmp.style,
          sourcePosition: "bottom",
          targetPosition: "top",
          type: styleTmp.type,
        });
      });

      let newEdges = [];
      data.edges = orderTransitions(data.edges);
      data.edges.forEach((edge) => {
        //if exist in newEdge a edge with the same source and target, then dont add it
        let exist = newEdges.some(
          (e) => e.source === edge.source && e.target === edge.target,
        );

        if (!exist) {
          let transitions = [];
          transitions.push(edge.data);
          edge.data.transitions = cloneDeep(transitions);
          //circular is if A->B and B->

          edge.data.isCircular = data.edges.some(
            (e) => e.source === edge.target && e.target === edge.source,
          );

          // TEMPORAL FUNCTION TO DEFINE EDGE STRUCTURE
          const redifinedEdge = redifineEdge(edge);

          newEdges.push({
            ...redifinedEdge,
            animated: false,
            type: "smoothstep",
            markerEnd: {
              color: "#ccc",
              height: "25px",
              type: "arrowclosed",
              width: "25px",
            },
          });
        } else {
          let index = newEdges.findIndex(
            (e) => e.source === edge.source && e.target === edge.target,
          );
          newEdges[index].data.transitions.push(edge.data);
          newEdges[index].label = newEdges[index].label + "\n" + edge.label;
        }
      });

      setNodes(newNodes);
      setEdges(newEdges);

      let tempNodes = JSON.parse(JSON.stringify(newNodes));
      let tempEdges = JSON.parse(JSON.stringify(newEdges));
      let previous = JSON.parse(
        JSON.stringify({ nodes: tempNodes, edges: tempEdges }),
      );
      setPrevious(previous);
    });
  }, [agentSelected, setNodes, setEdges]);

  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((node) => node.id === params.source);
      const targetNode = nodes.find((node) => node.id === params.target);

      if (!sourceNode || !targetNode || sourceNode.id === targetNode.id) {
        return;
      }

      // Create base condition data without circular references
      const baseConditionData = {
        is_new: true,
        label: intents[0]?.name || "",
        message: "",
        oid: "",
        type: "intent",
        conditions: [],
        intents: [
          intents.find((intent) =>
            intent.name?.toLowerCase()?.startsWith("default"),
          )?.name || "",
        ],
        entity: {},
        cleanParams: [],
        presets: [],
        sourceId: sourceNode.id,
        targetId: targetNode.id,
        sourceName: sourceNode.data.label,
        targetName: targetNode.data.label,
        labelX:
          sourceNode.position.x +
          (targetNode.position.x - sourceNode.position.x) / 2,
        labelY:
          sourceNode.position.y +
          (targetNode.position.y - sourceNode.position.y) / 2,
      };

      // Create new edge object
      const newEdge = {
        ...params,
        id: `e${params.source}-${params.target}`,
        source: params.source,
        target: params.target,
        type: "smoothstep",
        animated: false,
        data: {
          transitions: [],
        },
        labelStyle: { fill: "white" },
        labelBgStyle: {
          fill: "#20212E",
          strokeWidth: "2",
          stroke: "#DFBD14",
        },
        style: {
          stroke: "#DFBD14",
          strokeWidth: "2px",
        },
        is_new: true,
      };

      // Handle multiple edges between same nodes
      const edgeCount = edges.filter(
        (e) => e.source === sourceNode.id && e.target === targetNode.id,
      ).length;

      if (edgeCount > 0) {
        newEdge.id = `${newEdge.id}-${edgeCount}`;
        const existingEdges = edges.filter(
          (e) => e.source === sourceNode.id && e.target === targetNode.id,
        );
        const lastEdge = existingEdges[existingEdges.length - 1];

        // Clone conditions data without circular references
        newEdge.data.transitions = lastEdge.data.transitions.map(
          (condition) => ({
            ...condition,
            sourceId: condition.sourceId || sourceNode.id,
            targetId: condition.targetId || targetNode.id,
            sourceName: condition.sourceName || sourceNode.data.label,
            targetName: condition.targetName || targetNode.data.label,
          }),
        );

        setEdgeSelectedTab(newEdge.data.transitions.length);
      }

      // Add the new condition to transitions without circular references
      newEdge.data.transitions.push({
        ...baseConditionData,
      });

      // Create edge for edit modal with necessary node information
      const edgeForEdit = {
        ...newEdge,
        source: { ...sourceNode }, // Clone source node
        target: { ...targetNode }, // Clone target node
        data: {
          ...newEdge.data,
          sourceId: sourceNode.id,
          targetId: targetNode.id,
          sourceName: sourceNode.data.label,
          targetName: targetNode.data.label,
        },
      };

      // Add edge to flow
      setEditEdge(edgeForEdit);
      setOpenEdgeModal(true);
      setIsSaveChanges(true);
    },
    [nodes, edges, intents, setEdgeSelectedTab],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  //onDrop is used to create a node at the moment of dropping the drag.
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      let tempNodeStyle = customNodeStyles({ node_type: type });

      const newNode = {
        id: newObjectId(),
        type,
        position,
        data: { label: getNewNodeName(nodes) },
        style: tempNodeStyle.style,
        is_new: true,
        sourcePosition: "right",
        targetPosition: "left",
      };

      setNodes((nds) => nds.concat(newNode));
      setIsSaveChanges(true);
    },
    [reactFlowInstance, nodes, customNodeStyles, setNodes],
  );

  const onNodeDragStop = (event, node) => {
    handleSaveNode(node);
  };

  useEffect(() => {
    getAgentNames();
    getEndpoints();
  }, []);

  useEffect(() => {
    if (agentSelected !== "") {
      getPages();
    }
  }, [agentSelected, getPages]);

  useEffect(() => {
    if (searchCoords.x !== 0 && searchCoords.y !== 0 && !isFromPublish) {
      reactFlowInstance.setCenter(searchCoords.x, searchCoords.y, {
        duration: 800,
        zoom: 1,
      });
      setIsFromPublish(false);
    }
  }, [searchCoords, reactFlowInstance]);

  const handleOpenNodeModal = (event, element) => {
    let editNode = JSON.parse(JSON.stringify(element));
    let ep = endpoints.find((x) => x.name === editNode.data.label);
    if (ep === undefined) {
      ep = {
        name: "",
        type: "POST",
        host: "",
        route: "",
        params: [],
      };
    }
    editNode = setAgentTransferToNodeModal(editNode);
    setEditNode(cloneDeep(editNode));
    setSelectedEndpoint(ep);
    setOpenNodeModal(true);
  };

  // If there is an agent transfer, we set the agent and the page to the selected element
  const setAgentTransferToNodeModal = (editNode) => {
    const noneElement = { name: "", display_name: "None" };

    // Construct transfer agent select
    let tempAgentsArray = JSON.parse(JSON.stringify(agentNames));
    let currentAgent = tempAgentsArray.find(
      (agent) => agent.name === agentSelected,
    ); // Remove current agent from list of agents to transfer to

    tempAgentsArray.splice(tempAgentsArray.indexOf(currentAgent), 1);
    tempAgentsArray.unshift(noneElement); // Add None item to be able to clear the component
    editNode.data.transfer_agent = [].concat(tempAgentsArray);

    // Construct transfer agent page select
    let tempAgentsPagesArray = JSON.parse(JSON.stringify(pagesLean));
    tempAgentsPagesArray = tempAgentsPagesArray.filter((page) =>
      page.agent === editNode.data.transferToAgent?.agent
        ? editNode.data.transferToAgent.agent
        : null,
    );
    tempAgentsPagesArray.unshift(noneElement);
    editNode.data.transfer_agent_pages = [].concat(tempAgentsPagesArray);

    return editNode;
  };

  const handleClose = () => {
    setOpenNodeModal(false);
    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
    setEditNode(null);
    setEdgeSelectedTab(0);
  };

  const validateTransferNodeTransitions = (edit) => {
    return edges.some(
      (e) => e.source === edit.id && edit.data.transferToAgent?.agent,
    );
  };

  const handleSaveNode = (edit) => {
    let nds = JSON.parse(JSON.stringify(nodes));

    // Validate if the node has transitions and is a transfer node
    let transferNodeHasTransitions = validateTransferNodeTransitions(edit);

    if (transferNodeHasTransitions) {
      enqueueSnackbar(t("flowgraph:ErrorHasTransitions"), {
        variant: "error",
      });
    }

    if (!transferNodeHasTransitions) {
      for (let i = 0; i < nds.length; i++) {
        if (nds[i].id === edit.id) {
          const tempNodeStyle = customNodeStyles(edit);
          nds[i] = {
            ...edit,
            selected: false,
            style: tempNodeStyle.style,
            is_modified: true,
            timestamp: new Date(),
            type: tempNodeStyle.type,
          };
        }
      }
    }

    setOpenNodeModal(false);
    setEditNode(null);
    setNodes(nds);
    setIsSaveChanges(true);
  };

  const getIntents = () => {
    get("/intents?agent=" + agentSelected)
      .then(async (data) => {
        // Remove intents that have a response since we can't use them to transition
        let intents = data.filter(
          (intent) => intent.response?.text?.length === 0,
        );
        setIntents(intents);
        setIntentsqr(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingIntents"), {
          variant: "error",
        });
      });
  };

  const getAllPages = () => {
    get("/pages/all")
      .then(async (data) => {
        setPagesLean(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPagesForAgentTransfers"), {
          variant: "error",
        });
      });
  };

  const getEndpoints = () => {
    get("/endpoints")
      .then(async (data) => {
        setEndpoints(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingEndpoints"), {
          variant: "error",
        });
      });
  };

  const getTraces = async () => {
    const tracesFlowgraph = await get("/traces/getFlow?agent=" + agentSelected);
    setTraces(tracesFlowgraph);
  };

  const handleDelete = async () => {
    await remove("/traces/publish", {
      agent: agentSelected,
      collectionTraces: "flow",
    })
      .then((e) => {
        if (e.errors) {
          for (const err of e.errors) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        }
        getTraces();
        getPages();
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorDeleteTraces"), {
          variant: "error",
        });
      });
    setIsSaveChanges(false);
  };

  const handlePublish = async () => {
    await post("/traces/publishFlow?agent=" + agentSelected)
      .then(async () => {
        enqueueSnackbar(t("intents:newChangesPublish"), {
          variant: "success",
        });
        setIsFromPublish(true);
        getPages();
        getTraces();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(
          `${errorMessage} :` + t("flowgraph:errorPublishingFlowgraph"),
          {
            variant: "error",
          },
        );
      });
    setIsSaveChanges(false);
  };

  const handleDeleteNode = () => {
    onElementsRemove([editNode]);
  };

  const onElementsRemove = (elementsToRemove) => {
    let nds = JSON.parse(JSON.stringify(nodes));
    let eds = JSON.parse(JSON.stringify(edges));
    let tmpDeleteElements = JSON.parse(JSON.stringify(deleteElements));

    let tempNodes = JSON.parse(JSON.stringify(nodes));
    let tempEdges = JSON.parse(JSON.stringify(edges));
    let previous = JSON.parse(
      JSON.stringify({ nodes: tempNodes, edges: tempEdges }),
    );
    setPrevious(previous);

    for (let x = 0; x < nds.length; x++) {
      for (let i = 0; i < elementsToRemove.length; i++) {
        // Prevenir eliminar el estado inicial (input)
        if (
          elementsToRemove[i].id === nds[x].id &&
          elementsToRemove[i].node_type !== "input" &&
          elementsToRemove[i].node_type !== "output"
        ) {
          const newEdges = [];
          for (const edge of eds) {
            // If transition has the deleted node as source or target,
            // remove it and add to deleteElements
            if (
              elementsToRemove[i].id === edge.source ||
              elementsToRemove[i].id === edge.target
            ) {
              if (
                !elementsToRemove[i].is_new ||
                traces.some((t) => t.changes.id === elementsToRemove[i].id)
              ) {
                tmpDeleteElements.edges.push(edge);
              }
            }
            // If transition is not connected to the deleted node, keep it
            else {
              newEdges.push(edge);
            }
          }
          eds = newEdges;
          if (
            !elementsToRemove[i].is_new ||
            traces.some((t) => t.changes.id === elementsToRemove[i].id)
          ) {
            tmpDeleteElements.pages.push(elementsToRemove[i]);
          }
          nds.splice(x, 1);
        }
      }
    }
    setNodes(nds);
    setEdges(eds);
    setOpenEdgeModal(false);
    setOpenNodeModal(false);
    setSelectedEndpoint(undefined);
    setDeleteElements(tmpDeleteElements);
    setIsSaveChanges(true);
  };

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "flows" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSaveGraph = () => {
    //TODO: When this function is done, check the traces (is_modified)
    post("/flows", {
      agent: agentSelected,
      elements: nodes.concat(edges),
      endpoints: endpoints,
      deleteElements: deleteElements,
      flowgraphVersion: 2,
    })
      .then(async (response) => {
        if (response) {
          enqueueSnackbar(t("flowgraph:graphSaved"), {
            variant: "success",
          });
          deleteIsModified();
          getTraces();
        } else {
          enqueueSnackbar(t("flowgraph:featureDisabled"), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPages"), {
          variant: "error",
        });
      });
    setIsSaveChanges(false);
  };

  const handleSaveEdge = useCallback((edit) => {
    setEdges((prevEdges) => {
      // Create clean edge data without circular references
      const updatedEdge = {
        ...edit,
        source: edit.source.id,
        target: edit.target.id,
        label: edit.data.label,
        is_modified: true,
        timestamp: new Date(),
        data: {
          ...edit.data,
          sourceId: edit.source.id,
          targetId: edit.target.id,
          sourceName: edit.source.data.label,
          targetName: edit.target.data.label,
          // Clean up transitions to prevent circular references
          transitions: edit.data.transitions.map((condition) => ({
            ...condition,
            collectionName: "edge",
            sourceId: edit.source.id,
            targetId: edit.target.id,
            sourceName: edit.source.data.label,
            targetName: edit.target.data.label,
          })),
          isCircular: prevEdges.some(
            (e) => e.source === edit.target.id && e.target === edit.source.id,
          ),
        },
      };

      const index = prevEdges.findIndex((x) => x.id === edit.id);

      if (index === -1) {
        return [...prevEdges, updatedEdge];
      }

      return prevEdges.map((edge) =>
        edge.id === edit.id ? updatedEdge : edge,
      );
    });

    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
    setEdgeSelectedTab(0);
    setIsSaveChanges(true);
  }, []);

  const handleDeleteTab = (editTransition, editEdge) => {
    let egs = cloneDeep(edges);
    let previous = cloneDeep({ nodes: nodes, edges: egs });

    let index = egs.findIndex((x) => x.id === editEdge.id);
    let tmpEditEdge = cloneDeep(editEdge);
    tmpEditEdge.is_modified = true;
    tmpEditEdge.timestamp = new Date();
    tmpEditEdge.source = editEdge.source.id;
    tmpEditEdge.target = editEdge.target.id;
    egs[index] = tmpEditEdge;

    setPrevious(previous);
    setEdges(egs);
    if (
      !editTransition.is_new ||
      traces.some((t) => t.changes.id === tmpEditEdge.id)
    ) {
      setDeleteElements({
        pages: deleteElements.pages,
        edges: deleteElements.edges.concat(editTransition),
      });
    }
  };

  const handleDeleteEdge = (editTransition) => {
    let egs = cloneDeep(edges);
    let tmpEditEdge = cloneDeep(editEdge);

    if (tmpEditEdge.data.isCircular) {
      const inverseEdge = egs.find(
        (edge) => edge.target === tmpEditEdge.source,
      );

      if (inverseEdge) {
        // Aquí puedes eliminar la propiedad isCircular del edge inverso
        const index = edges.indexOf(inverseEdge);
        if (index > -1) {
          egs[index].data.isCircular = false;
          setEdges(egs);
        }
      }
    }

    if (
      !editTransition.is_new ||
      traces.some((t) => t.changes.id === tmpEditEdge.id)
    ) {
      setDeleteElements({
        pages: deleteElements.pages,
        edges: deleteElements.edges.concat(editTransition),
      });
    }

    reactFlowInstance.deleteElements({ edges: [tmpEditEdge] });

    setOpenEdgeModal(false);
    setEditEdge({ data: {} });
    setIsSaveChanges(true);
  };

  const handleOpenEdgeModal = (event, element) => {
    let selectedElement = cloneDeep(element);

    selectedElement.target = cloneDeep(
      reactFlowInstance.getNode(element.target),
    );
    selectedElement.source = cloneDeep(
      reactFlowInstance.getNode(element.source),
    );
    setOpenEdgeModal(true);
    setEditEdge(selectedElement);
  };

  const handleSearch = (event) => {
    if (event) {
      reactFlowInstance.setCenter(event.position.x, event.position.y, {
        duration: 800,
        zoom: 1,
      });
    }
  };

  /*TODO: This function should be removed in the future, as it is currently being used to keep the changes visible. 
  In the future, when pages are brought in with unapplied traces, it will no longer be necessary.*/
  const deleteIsModified = () => {
    //for edges and nodes delete data.is_modified
    let nds = cloneDeep(nodes);
    let egs = cloneDeep(edges);
    for (const element of nds) {
      delete element.is_modified;
      delete element.is_new;
      delete element.timestamp;
    }
    for (const element of egs) {
      for (const transition of element.data.transitions) {
        delete transition.is_modified;
        delete transition.is_new;
        delete transition.timestamp;
      }
    }
    setNodes(nds);
    setEdges(egs);
    setDeleteElements({ pages: [], edges: [] });
  };

  const layoutFlow = () => {
    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      startPosition,
    } = getLayoutedElements(nodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    reactFlowInstance.setCenter(startPosition.x + 200, startPosition.y + 200, {
      duration: 800,
      zoom: 0.6,
    });
  };

  return (
    <ReactFlowProvider>
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: "#141414" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "2rem",
          }}>
          <Bar
            agentSelected={agentSelected}
            setAgentSelected={setAgentSelected}
            agentNames={agentNames}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            onSave={handleSaveGraph}
            colors={colors}
            nodes={nodes}
            onSearch={handleSearch}
            layoutFlow={layoutFlow}
            isSaveChanges={isSaveChanges}
            setIsSaveChanges={setIsSaveChanges}
          />
        </div>
        <EdgeModalTabsPanel
          open={openEdgeModal}
          onClose={handleClose}
          editEdgeProps={editEdge}
          previous={previous}
          onSave={handleSaveEdge}
          onDelete={handleDeleteEdge}
          intents={intents}
          edges={edges}
          pages={nodes}
          userPermissions={userPermissions}
          propsSelectedTab={edgeSelectedTab}
          onDeleteTab={(editTransition, editEdge) =>
            handleDeleteTab(editTransition, editEdge)
          }
        />
        <NodeModal
          agent={agentSelected}
          open={openNodeModal}
          editNodeProps={editNode}
          nodes={nodes}
          onClose={handleClose}
          previous={previous}
          onSave={handleSaveNode}
          onDelete={handleDeleteNode}
          pagesLean={pagesLean}
          endpoints={endpoints}
          userPermissions={userPermissions}
          intents={intentsqr}
          endpointProps={selectedEndpoint}
        />
        <ReactFlow
          className="reactflow-v2"
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={onNodeDragStop}
          onInit={setReactFlowInstance}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          onNodeDoubleClick={handleOpenNodeModal}
          onEdgeDoubleClick={handleOpenEdgeModal}
          onEdgeClick={handleEdgeClick}
          deleteKeyCode={null}
          selectionMode={"partial"}
          onNodesDelete={onElementsRemove}>
          <Background color="#141414" variant="dots" />
        </ReactFlow>
        <TracesHistory
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          agentPages={agentSelected}
          traces={traces}
        />
      </div>
    </ReactFlowProvider>
  );
}
