import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  TextField,
  Switch,
  Input,
  Dialog,
  DialogActions,
  Typography,
  Autocomplete,
} from "@mui/material";
import ChipInput from "../../shared/components/ChipInput";
import TextDnD from "../../shared/components/TextDnD/TextDnD.component";
import PropTypes from "prop-types";

const EditAgentVarsModal = ({
  selectedAgentVar,
  onClose,
  onSave,
  open,
  agentPages,
}) => {
  const { t } = useTranslation("agents");
  const [tempAgentVar, setTempAgentVar] = useState({});
  const [error, setError] = useState("");
  const vars_disabled = ["no_media_message", "no_match_message_alt"];
  const varSpecial = ["time_zone"];

  useEffect(() => {
    setTempAgentVar(selectedAgentVar);
    validate_vars(selectedAgentVar, vars_disabled);
  }, [open]);

  useEffect(() => {
    validate_vars(tempAgentVar, vars_disabled);
  }, [tempAgentVar]);

  const validateSpecialVar = () => {
    const { var_name, var_value } = tempAgentVar;

    if (var_name === "time_zone" && (var_value < -12 || var_value > 14)) {
      setError("Wrong time zone");
      return false;
    }

    return true;
  };

  const validate_vars = (agentVar, vars_disabled) => {
    let valid = true;
    if (
      vars_disabled.includes(agentVar.var_name) &&
      agentVar.var_value === ""
    ) {
      valid = false;
      setError("Var Disabled");
    }
    if (
      agentVar.var_type === "object" &&
      Boolean(agentVar.var_value.label) != Boolean(agentVar.var_value.value)
    ) {
      valid = false;
      setError("Property object empty");
    }
    if (varSpecial.includes(agentVar.var_name)) {
      valid = validateSpecialVar();
    }
    if (valid) {
      setError("");
    }
  };

  const handleOnChange = (e, selectedVar) => {
    let tempVars = { ...tempAgentVar };
    if (selectedVar.var_type === "bool") {
      tempVars.var_value = e.target.checked;
    } else if (selectedVar.var_type === "sarray") {
      tempVars.var_value = e;
    } else if (
      selectedVar.var_type === "int" ||
      selectedVar.var_type === "float"
    ) {
      tempVars.var_value = e.target.value;
    } else if (selectedVar.var_type === "object") {
      tempVars.var_value = e;
    } else if (selectedVar.var_type === "string") {
      tempVars.var_value = e.target.value;
    } else if (selectedVar.var_type === "page") {
      tempVars.var_value = agentPages.find((page) => page.data.label === e)?.id;
    }
    setTempAgentVar(tempVars);
  };

  const renderInputField = () => {
    let { var_type, var_value } = tempAgentVar;

    switch (var_type) {
      case "sarray": {
        if (tempAgentVar?.var_name === "flows_feedback_steps") {
          return (
            <div>
              <ChipInput
                id="edit-modal-chip"
                disableUnderline
                name="value"
                source="value"
                value={var_value}
                updateProps={(e) => handleOnChange(e, tempAgentVar)}
              />
            </div>
          );
        } else {
          return (
            <TextDnD
              id="edit-modal-chip"
              title={"value"}
              setItems={(e) => handleOnChange(e, tempAgentVar)}
              items={var_value}
            />
          );
        }
      }
      case "bool": {
        return (
          <Switch
            id="edit-modal-switch"
            name="value"
            checked={var_value}
            onChange={(e) => handleOnChange(e, tempAgentVar)}
          />
        );
      }
      case "int": {
        return (
          <Input
            id="edit-modal-input"
            fullWidth
            name="value"
            placeholder="Value"
            value={var_value}
            type="number"
            onChange={(e) => handleOnChange(e, tempAgentVar)}
          />
        );
      }

      case "float": {
        return (
          <TextField
            id="edit-modal-float"
            fullWidth
            name="value"
            placeholder="Value"
            value={var_value}
            type="number"
            onChange={(e) => handleOnChange(e, tempAgentVar)}
            inputProps={{ step: "0.1" }}
          />
        );
      }

      case "object": {
        return (
          <div>
            <TextField
              id="edit-modal-text1"
              variant="standard"
              value={var_value.label}
              label={t("AgentVarObjectTitle")}
              onChange={(e) =>
                handleOnChange(
                  {
                    label: e.target.value,
                    value: tempAgentVar.var_value.value,
                  },
                  tempAgentVar,
                )
              }
            />
            <TextField
              id="edit-modal-text2"
              style={{ marginLeft: "10px" }}
              variant="standard"
              label={t("AgentVarObjectPayload")}
              value={tempAgentVar.var_value.value}
              onChange={(e) =>
                handleOnChange(
                  {
                    label: tempAgentVar.var_value.label,
                    value: e.target.value,
                  },
                  tempAgentVar,
                )
              }
            />
          </div>
        );
      }

      case "page": {
        return (
          <Autocomplete
            id="edit-modal-page"
            variant="standard"
            fullWidth
            value={
              agentPages.find(
                (page) =>
                  page.id === var_value || page.data?.label === var_value, // Look for the page by id or label
              )?.data?.label || var_value // If the page is not found, use the value
            }
            options={agentPages.map((page) => page.data.label)}
            onChange={(e, value) => handleOnChange(value, tempAgentVar)}
            sx={{ minWidth: "200px" }}
            renderInput={(params) => (
              <Box display="flex" flexDirection="row">
                <TextField
                  {...params}
                  id="edit-modal-autocomplete-textfield"
                  variant="standard"
                  className="transition-message"
                  fullWidth></TextField>
              </Box>
            )}
          />
        );
      }

      default: {
        return (
          <Input
            id="edit-modal-input-default"
            fullWidth
            value={var_value}
            multiline
            onChange={(e) => handleOnChange(e, tempAgentVar)}
          />
        );
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
        {t("modalVarTitleEdit") + ` ${tempAgentVar?.parentName}`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection:
              tempAgentVar?.var_type === "sarray" ? "column" : "row",
          }}>
          <Grid item xs={6}>
            <Typography>{tempAgentVar?.var_name}</Typography>
          </Grid>
          <Grid>
            <div>{renderInputField()}</div>
          </Grid>
        </Grid>
      </Box>

      <DialogActions>
        <Button
          id="cancel-button"
          onClick={() => {
            onClose();
          }}>
          {t("modalCancel")}
        </Button>
        <Button
          id="save-button"
          variant="contained"
          color="primary"
          disabled={!!error}
          onClick={() => {
            onSave(tempAgentVar);
          }}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
EditAgentVarsModal.propTypes = {
  selectedAgentVar: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  agentPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default EditAgentVarsModal;
