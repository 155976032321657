import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import ChatTab from "./Components/ChatTab.Component";
import ButtonTab from "./Components/ButtonTab.Component";
import { put, get } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { setRenderChat } from "../shared/redux/clientSlice";

const Personalization = () => {
  const { t } = useTranslation("personalization");
  const [tab, setTab] = useState();
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);
  const client = useSelector((state) => state.client);
  const { enqueueSnackbar } = useSnackbar();
  const [chatSettings, setChatSettings] = React.useState({});
  const dispatch = useDispatch();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
    getUserPermisions();
    getChatCConfig();
  }, [client.name]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getChatCConfig = () => {
    let body = { clientName: client.name };
    get("/file/ChatCConfig/", body)
      .then(async (res) => {
        setChatSettings({
          clientName: client.name,
          chatTitle: res.chatTitle,
          url: res.url,
          size: res.size,
        });
      })
      .catch(() => {});
  };

  const saveChatConfig = (body) => {
    body = { ...body, clientName: client.name };
    put("/file/ChatCConfig/", body)
      .then(async () => {
        enqueueSnackbar(t("personalization:fileUpdated"), {
          variant: "success",
        });
        dispatch(setRenderChat());
        setChatSettings(body);
      })
      .catch(() => {
        enqueueSnackbar(t("personalization:errorUploadingFile"), {
          variant: "error",
        });
      });
  };

  const renderTab = () => {
    switch (tab) {
      case "chat":
        return (
          <ChatTab
            userPermissionsProps={userPermissions}
            client={client}
            saveChatConfig={saveChatConfig}
            chatSettingsProps={chatSettings}
          />
        );
      case "button":
        return <ButtonTab />;
      default:
        return <ButtonTab />;
    }
  };

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "file" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  return (
    <React.Fragment key={key}>
      <Container maxwidth={false} style={{ maxWidth: "100%" }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: "10px",
          }}>
          <Typography
            align="left"
            variant="h4"
            sx={{ padding: "30px 0px 30px 50px" }}>
            {t("personalization")}
          </Typography>
          <Box
            sx={{ display: "flex", flexGrow: 1, bgcolor: "background.paper" }}>
            <Tabs
              textColor="primary"
              visibleScrollbar
              selectionFollowsFocus="true"
              orientation="vertical"
              variant="scrollable"
              value={tab}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                margin: "20px",
              }}>
              <Tab label={t("button")} value={"button"} />
              <Tab label={t("chat")} value={"chat"} />
            </Tabs>
            {renderTab()}
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Personalization;
