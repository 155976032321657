// React imports
import React, { useState, useEffect } from "react";
// Material UI imports
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Collapse,
  Autocomplete,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//Icons
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// Styles
import "./Bar.component.css";

import TextField from "@mui/material/TextField";

import UnsavedChangesDialog from "../../../shared/components/UnsavedChangesDialog";

function Bar({
  agentSelected,
  setAgentSelected,
  agentNames,
  zoomIn,
  zoomOut,
  onSave,
  nodes,
  onSearch,
  layoutFlow,
  isSaveChanges,
  setIsSaveChanges,
}) {
  //new node drag
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  const { t } = useTranslation("flowgraph");
  const [openAlert, setOpenAlert] = useState(false);
  const [pendingAgent, setPendingAgent] = useState(null);

  useEffect(() => {
    setSearchValue("");
  }, [agentSelected]);

  const handleChangeAgent = (event) => {
    if (isSaveChanges) {
      setPendingAgent(event.target.value); 
      setOpenAlert(true);
    } else {
      setAgentSelected(event.target.value);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleAcceptAlert = () => {
    if (pendingAgent) {
      setAgentSelected(pendingAgent);
      setPendingAgent(null);
    }
    setIsSaveChanges(false); 
    setOpenAlert(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#323232",
        border: "1px solid #1e1e1e",
        borderRadius: "15px",
        zIndex: 100,
        boxShadow: 15,
        flexDirection: "row",
        margin: "1em",
      }}>
      <Button
        sx={{ marginLeft: "0.5em" }}
        className={"dndnode input"}
        id="dndnode-input"
        color="textColor"
        draggable
        onDragStart={(event) => {
          onDragStart(event, "default");
          const dragElem = document.createElement("div");
          dragElem.className = "dragging-node";
          dragElem.textContent = "New Node";
          document.body.appendChild(dragElem);
          event.dataTransfer.setDragImage(dragElem, 0, 0);
        }}
        onDragEnd={() => {
          document.querySelector(".dragging-node").remove();
        }}
        onKeyDown={(event) => {
          // Allow dragging to be initiated with the Enter or Space key
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            const dragEvent = new Event("dragstart", { bubbles: true });
            event.target.dispatchEvent(dragEvent);
          }
        }}
        style={{
          width: "20%",
          cursor: "grab",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <AddIcon />
        {t("newNodeButton")}
      </Button>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <Button
        id="undo-button"
        color="textColor"
        disabled
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}>
        <UndoIcon disabled sx={{ color: "#7E7E7E" }} />
        {t("undoButton")}
      </Button>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <Button
        id="save-button"
        onClick={onSave}
        color="textColor"
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        startIcon={<SaveIcon sx={{ color: "#fff" }} />}>
        {t("saveButton")}
      </Button>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <div
        style={{
          width: "30%",
        }}>
        <FormControl sx={{ pt: 1, pb: 1, minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-label">Agent</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agentSelected}
            label="Agent"
            onChange={(e) => handleChangeAgent(e)}>
            {agentNames.map((agent) => (
              <MenuItem key={agent.name} value={agent.name}>
                {agent.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <div
        style={{
          width: "20%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}>
        <IconButton color="textColor">
          <ZoomInIcon
            onClick={zoomIn}
            sx={{ cursor: "pointer", height: "100%" }}
          />
        </IconButton>
        {t("zoomButton")}
        <IconButton color="textColor">
          <ZoomOutIcon
            onClick={zoomOut}
            sx={{ cursor: "pointer", height: "100%" }}
          />
        </IconButton>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <Button
        id="search-button"
        color="textColor"
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsSearchOpen(!isSearchOpen)}>
        <SearchIcon sx={{ cursor: "pointer" }} />
        {t("searchButton")}
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}>
        <Collapse orientation="horizontal" in={isSearchOpen}>
          <Autocomplete
            id="search"
            value={searchValue}
            options={nodes.sort((a, b) =>
              a.data.label.toUpperCase() > b.data.label.toUpperCase() ? 1 : -1,
            )}
            getOptionLabel={(option) =>
              option?.data
                ? `${option.data.label} ${option.data.responseMessage}`
                : ""
            }
            sx={{ width: 200, mr: 1 }}
            onChange={(e, value) => {
              setSearchValue(value);
              onSearch(value);
            }}
            renderOption={(props, option) => (
              <div
                {...props}
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}>
                <div>
                  <Typography variant="subtitle1" align="left">
                    {option.data.label}
                  </Typography>
                </div>
                {option.data.responseMessage &&
                  option.data.responseMessage.length > 0 &&
                  option.data.responseMessage.map((message, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      align="left"
                      style={{ color: "darkgray" }}>
                      {message}
                    </Typography>
                  ))}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                id="search-input"
                {...params}
                label="Search"
                variant="outlined"
              />
            )}
          />
        </Collapse>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ margin: "0.5em" }}
      />
      <Button
        id="layout-button"
        color="textColor"
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => layoutFlow()}>
        <AccountTreeIcon sx={{ cursor: "pointer" }} />
        {t("layoutButton")}
      </Button>
      <UnsavedChangesDialog
        open={openAlert}
        onClose={handleCloseAlert}
        onAccept={handleAcceptAlert}
      />
    </Box>
  );
}

Bar.propTypes = {
  agentSelected: PropTypes.string.isRequired,
  setAgentSelected: PropTypes.func.isRequired,
  agentNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        responseMessage: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    }),
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
  layoutFlow: PropTypes.func.isRequired,
  setIsSaveChanges: PropTypes.func.isRequired, 
};

export default React.memo(Bar);
