import React, { useMemo, useState, useEffect } from "react";
import { VictoryAxis, VictoryChart, VictoryTooltip, VictoryBar } from "victory";
import Paper from "@mui/material/Paper";
import { get } from "../../shared/http/httpService";
import ChartTheme from "../theme/theme";
import Typography from "@mui/material/Typography";
import { Button, InputLabel } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import ChannelSelect from "../../shared/components/ChannelSelect";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  containerSection: {
    flex: 0.5,
  },
  containerFormLogin: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "14%",
    paddingRight: "14%",
    justifyContent: "center",
  },
  titleLogin: {
    textAlign: "center",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "center",
    display: "flex",
  },
  button: {
    height: "40px",
    width: "100px",
    backgroundColor: "#D9D9D9",
    color: "black",
    borderRadius: "5px",
    marginRight: "5px",
    marginLeft: "20px",
  },
}));

const ConversationalInteractions = () => {
  const classes = useStyles();
  let chartTheme = ChartTheme();
  const theme = useSelector((state) => state.theme);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const [graphicData, setGraphicData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalConversations, setTotalConversations] = useState(0);
  const [valid, setValid] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [channelsSelected, setChannelsSelected] = useState([]);

  const [fileName, setFileName] = useState(
    t("analytics:interactionsPerConversationFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );

  const client = useSelector((state) => state.client);

  useEffect(() => {
    setTotal(0);
    setTotalConversations(0);
    setGraphicData([]);
    setTableData([]);
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().startOf("day"));
  }, [client.name]);

  const columns = useMemo(() => [
    {
      header: t("conversationalInteractions:userId"),
      accessorKey: "user",
    },
    {
      header: t("conversationalInteractions:interactions"),
      accessorKey: "count",
    },
    {
      header: t("conversationalInteractions:date"),
      accessorKey: "date",
      Cell: ({ cell }) => {
        const formattedDate = cell.getValue().format("DD/MM/YYYY HH:mm:ss");
        return <InputLabel>{formattedDate}</InputLabel>;
      },
    },

    {
      header: t("conversationalInteractions:conversationId"),
      accessorKey: "_id",
    },
  ]);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((row) => {
      let channelName = row.original.channel;
      if (row.channel === "MESSENGER") {
        channelName = "FACEBOOK";
      } else if (row.channel === "INSTAGRAMDIRECT") {
        channelName = "INSTAGRAM";
      } else if (row.channel === "msteams") {
        channelName = "TEAMS";
      }
      return {
        [t("conversationalInteractions:userId")]: row.original.user,
        [t("conversationalInteractions:conversationID")]: row.original._id,
        [t("analytics:date")]: new Date(row.original.date).toLocaleString(),
        [t("conversationalInteractions:interactions")]: row.original.count,
        [t("conversationalInteractions:channel")]: channelName,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchConvesationalInteractions = async () => {
    try {
      const data = await get("/report/conversationalInteractions", {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        timezone: startDate.format("Z"),
        channelsSelected: channelsSelected,
      });
      if (data.graphic.length === 1) {
        data.graphic.push({ _id: data.graphic[0]._id + 1, count: 0 });
        data.graphic.push({ _id: data.graphic[0]._id + 2, count: 0 });
        data.graphic.push({ _id: data.graphic[0]._id + 3, count: 0 });
        data.graphic.push({ _id: data.graphic[0]._id + 4, count: 0 });
      }
      const newData = data.graphic.map((conversation) => {
        return {
          x: conversation._id,
          y: conversation.count,
          label: `${conversation.count} convesation/s with: ${conversation._id} interaction`,
        };
      });
      setTotalConversations(
        data.graphic.reduce((acc, conversation) => acc + conversation.count, 0),
      );
      setTotal(
        newData.reduce((accumulator, object) => {
          return accumulator + object.x * object.y;
        }, 0),
      );
      setGraphicData(newData);
      setTableData(
        data.table.map((item) => ({
          ...item,
          date: dayjs(item.date),
        })),
      );
    } catch (error) {
      if (error.status === 202) {
        enqueueSnackbar(t("analytics:timeoutHandlingReport"), {
          variant: "error",
        });
      } else {
        // Handle other errors
        console.error("Error fetching conversational interactions:", error);
        throw error;
      }
    }
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);

      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:conversationalInteractionsFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
  });

  return (
    <Container maxwidth="lg">
      <div>
        <Paper
          style={{
            marginBottom: "15px",
            paddingTop: "10px",
            paddingBottom: "15px",
            paddingLeft: "5px",
          }}>
          <Typography variant="h4">
            {t("analytics:interactionsPerConversation")}
          </Typography>
          <br></br>
          <Grid container justify="space-between" alignItems="center">
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onDateSelected={handleDate}
            />
            <ChannelSelect setChannels={setChannelsSelected} />
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginLeft: "10px",
              }}>
              <Button
                id="convesational-interactions-fetch"
                className={classes.button}
                variant="contained"
                color="default"
                size="medium"
                disabled={!valid || channelsSelected.length === 0}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                onClick={fetchConvesationalInteractions}>
                {t("conversationalInteractions:fetch")}
              </Button>
              <Button
                id="convesational-interactions-download"
                className={classes.button}
                variant="contained"
                disabled={
                  table.getPrePaginationRowModel().rows.length === 0 ||
                  channelsSelected.length === 0
                }
                color="default"
                onClick={() => {
                  prepareDataForExport(table.getPrePaginationRowModel().rows);
                }}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                size="medium">
                {t("conversationalInteractions:download")}
              </Button>
            </div>
          </Grid>
          <VictoryChart
            fixLabelOverlap={true}
            responsive={false}
            height={300}
            width={400}
            animate={{
              duration: 500,
              onLoad: { duration: 200 },
            }}
            domainPadding={{ x: 30 }}
            padding={70}
            theme={chartTheme}>
            <VictoryBar
              barRatio={0.8}
              barWidth={5}
              cornerRadius={{ top: 2 }}
              style={{
                data: {
                  fill: "#B474DC",
                },
                labels: {
                  fontSize: 5,
                  fill: "black",
                },
              }}
              alignment="start"
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  pointerLength={0}
                  flyoutStyle={{
                    stroke: "grey",
                    backgroundColor: "red",
                  }}
                />
              }
              data={graphicData}
            />
            <VictoryAxis
              label={t("conversationalInteractions:amountInteractions")}
              style={{
                axisLabel: { padding: 30 },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={t("conversationalInteractions:amountConversations")}
              style={{
                axisLabel: { padding: 50 },
              }}
            />
          </VictoryChart>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              marginTop: "-30px",
            }}>
            {t("conversationalInteractions:totalConversations")}:{" "}
            {totalConversations}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              marginTop: "0px",
            }}>
            {" "}
            {t("conversationalInteractions:totalInteractions")}: {total}
          </Typography>
        </Paper>

        <Paper>
          <div style={{ maxWidth: "100%" }}>
            <Typography>
              <br />
              <h2>{t("conversationalInteractions:usersAndInteractions")}</h2>
            </Typography>
            <ThemeProvider
              theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
              <MaterialReactTable table={table} />
            </ThemeProvider>
          </div>
        </Paper>
      </div>
    </Container>
  );
};

export default ConversationalInteractions;
