import dayjs from "dayjs";
import moment from "moment";

const setLabel = (tempEditTransition) => {
  switch (tempEditTransition.type) {
    case "intent":
      tempEditTransition.label = tempEditTransition.intents.join(", ");

      break;

    case "condition":
      if (tempEditTransition.conditions.length > 0) {
        tempEditTransition.label = tempEditTransition.conditions
          .map((condition) => {
            return (
              condition.prop +
              conditionOperations(condition.op) +
              " " +
              condition.value
            );
          })
          .join(", ");
      }
      break;

    case "entity":
      tempEditTransition.label = "Entity: " + tempEditTransition.entity.name;
      break;

    case "media":
      tempEditTransition.label = "Media: " + tempEditTransition.media.name;
      break;

    case "date":
      if (tempEditTransition.conditions.length > 0) {
        tempEditTransition.label = tempEditTransition.conditions
          .map((condition) => {
            return conditionDates(condition);
          })
          .join(", ");
      }
      break;

    case "direct":
      tempEditTransition.label = "Direct Transition";
      break;

    default:
      tempEditTransition.label = "No valid transition";
      break;
  }
  return tempEditTransition;
};

const conditionOperations = (operation) => {
  let name = "";
  switch (operation) {
    case "eq":
      name = " =";
      break;
    case "neq":
      name = " !=";
      break;
    case "lt":
      name = " <";
      break;
    case "elt":
      name = " <=";
      break;
    case "gt":
      name = " >";
      break;
    case "egt":
      name = " >=";
      break;
    case "cgt":
      name = ".length >=";
      break;
    case "clt":
      name = ".length <=";
      break;
  }
  return name;
};

const conditionDates = (date) => {
  let name = "";
  let operator;
  switch (date.op) {
    case "eq":
      name = "=";
      break;
    case "neq":
      name = "!=";
      break;
    case "time":
      name =
        date.value.startTime.slice(0, 5) +
        " hs - " +
        date.value.endTime.slice(0, 5) +
        " hs";
      break;
    case "date":
      name =
        dayjs(date.value.startDate).format("MM/DD/YYYY") +
        " < 🗓️ " +
        " < " +
        dayjs(date.value.endDate).format("MM/DD/YYYY");
      break;
    case "day":
      name = date.value.join(" | ");
      break;
    case "holiday":
      operator = date.reverse ? "!" : "=";
      name = "Holiday 🗓️ " + operator + "= " + date.value.join(" | ");
      break;
    case "schedule":
      name = date.reverse ? "Out of Schedule" : "Schedule";
      break;
  }
  return name;
};

const validateIntentTransition = (transition, other) => {
  return !other.intents?.some((e) => transition.intents?.includes(e));
};

const validateConditionTransition = (transition, other) => {
  // Check if each object in transition.conditions exists in other.conditions
  const allMatch = transition.conditions.every((transObj) => {
    return other.conditions.some(
      (otherObj) =>
        transObj.op === otherObj.op &&
        transObj.prop === otherObj.prop &&
        transObj.value === otherObj.value,
    );
  });

  // Check if each object in other.conditions exists in transition.conditions
  const allReverseMatch = other.conditions.every((otherObj) => {
    return transition.conditions.some(
      (transObj) =>
        transObj.op === otherObj.op &&
        transObj.prop === otherObj.prop &&
        transObj.value === otherObj.value,
    );
  });

  return !allMatch && !allReverseMatch;
};

const validateEntityTransition = (transition, other) => {
  if (
    transition.entity?.value !== undefined &&
    other.entity?.value !== undefined &&
    transition.entity?.name !== undefined &&
    other.entity?.name !== undefined
  ) {
    return !(
      other.entity.value === transition.entity.value &&
      other.entity.name === transition.entity.name
    );
  }
  return true;
};

const validateMediaTransition = (transition, other) => {
  return other.media?.name !== transition.media?.name;
};

const validateTimeCondition = (change, condition, invalidConditions) => {
  const changeValue = change.value;
  const conditionValue = condition.value;
  const changeStart = moment(changeValue.startTime, "HH:mm:ss");
  const changeEnd = moment(changeValue.endTime, "HH:mm:ss");
  const conditionStart = moment(conditionValue.startTime, "HH:mm:ss");
  const conditionEnd = moment(conditionValue.endTime, "HH:mm:ss");
  if (changeStart.isSame(conditionStart) && changeEnd.isSame(conditionEnd)) {
    invalidConditions.push(condition);
  }
  if (
    changeStart.isBetween(conditionStart, conditionEnd) ||
    changeEnd.isBetween(conditionStart, conditionEnd) ||
    conditionStart.isBetween(changeStart, changeEnd) ||
    conditionEnd.isBetween(changeStart, changeEnd)
  ) {
    invalidConditions.push(condition);
  }
  return false;
};

const validateDateCondition = (change, condition, invalidConditions) => {
  const changeValue = change.value;
  const conditionValue = condition.value;
  const changeStartDate = moment(changeValue.startDate.toISOString()).startOf(
    "day",
  );
  const changeEndDate = moment(changeValue.endDate.toISOString()).endOf("day");
  const conditionStartDate = moment(conditionValue.startDate).startOf("day");
  const conditionEndDate = moment(conditionValue.endDate).endOf("day");

  if (
    (changeStartDate.isSameOrBefore(conditionStartDate, "day") &&
      changeEndDate.isSameOrAfter(conditionStartDate, "day")) ||
    (conditionStartDate.isSameOrBefore(changeStartDate, "day") &&
      conditionEndDate.isSameOrAfter(changeStartDate, "day"))
  ) {
    invalidConditions.push(condition);
  }
  return false;
};

const validateDayCondition = (change, condition, invalidConditions) => {
  const changeValue = change.value;
  const conditionValue = condition.value;
  let valid = true;
  for (let i = 0; i < 7; i++) {
    if (changeValue[i] !== "" || conditionValue[i] !== "") {
      if (changeValue[i] === conditionValue[i]) {
        valid = false;
        break;
      }
    }
  }
  if (!valid) {
    invalidConditions.push(condition);
  }
  return false;
};

const validateHolidayCondition = (change, condition, invalidConditions) => {
  const changeValue = change.value;
  const conditionValue = condition.value;
  const holidays = changeValue.some((holiday) =>
    conditionValue.includes(holiday),
  );
  if (holidays && change.reverse === condition.reverse) {
    invalidConditions.push(condition);
    return false;
  }
  return true;
};

const validateScheduleCondition = (change, condition, invalidConditions) => {
  if (change.reverse === condition.reverse) {
    invalidConditions.push(condition);
    return false;
  }
  return true;
};

// Transforms the holidays array into an array of objects with a single holiday
const transformHolidays = (array) => {
  let tempArray = [];

  array?.forEach((item) => {
    if (item.op === "holiday" && item.value.length > 1) {
      item.value.forEach((holiday) => {
        tempArray.push({
          op: item.op,
          reverse: item.reverse,
          value: [holiday],
        });
      });
    } else {
      tempArray.push(item);
    }
  });

  return tempArray;
};

export {
  setLabel,
  validateIntentTransition,
  validateConditionTransition,
  validateEntityTransition,
  validateMediaTransition,
  validateTimeCondition,
  validateDateCondition,
  validateDayCondition,
  validateHolidayCondition,
  validateScheduleCondition,
  transformHolidays,
};
