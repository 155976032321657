import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ChipInput from "../../../shared/components/ChipInput";
import ApiTest from "../../../shared/components/ApiTest";
import {
  validateNameCharacters,
  validateUrlRoute,
} from "../../../shared/helper/validations";

import TableMapper from "./Table.component";

const RouteItem = ({
  route,
  _id,
  arrayRoutes,
  modalDeleteRoute,
  saveRoute,
  userPermissions,
}) => {
  const { t } = useTranslation("apiRoutes");
  const [editing, setEditing] = useState();
  const [edit, setEdit] = useState();
  const [errors, setErrors] = useState();
  const [save, setSave] = useState();
  const [isNew, setIsNew] = useState({});
  const tableRef = useRef(null);
  const [errorMapped, setErrorMapped] = useState("");

  useEffect(() => {
    setEditing(route.editing);
    setErrors({});
    let tempEdit;
    if (route.editing) {
      if (route.add) {
        tempEdit = {
          _id: route._id,
          name: route.name,
          type: route.type,
          route: route.route,
          params: route.params,
          options: {
            file: route.options.file,
            filePosition: route.options.filePosition,
          },
          responseMapping: route.responseMapping,
          new: route.new,
          editing: route.editing,
        };
        delete route.add;
        delete arrayRoutes[_id].add;
      } else {
        tempEdit = {
          _id: route._id,
          name: edit?.name ?? route.name,
          type: edit?.type ?? route.type,
          route: edit?.route ?? route.route,
          params: edit?.params ?? route.params,
          options: {
            file: edit?.options?.file ?? route.options.file,
            filePosition:
              edit?.options?.filePosition ?? route.options.filePosition,
          },
          responseMapping: edit?.responseMapping ?? route.responseMapping,
          new: edit?.new ?? route.new,
          editing: route.editing,
        };
      }
    }
    setEdit(tempEdit);
    if (route.new) {
      const tempIsNew = {};
      if (!tempEdit?.name || tempEdit?.name === "") {
        tempIsNew.name = true;
      }
      if (!tempEdit?.route || tempEdit?.route === "") {
        tempIsNew.route = true;
      }
      setIsNew(tempIsNew);
    } else {
      const tempIsNew = {};
      tempIsNew.name = false;
      tempIsNew.route = false;
      setIsNew(tempIsNew);
    }
  }, [route]);

  useEffect(() => {
    if (editing) {
      validate();
    } else {
      setErrors({});
    }
  }, [edit]);

  const validate = () => {
    const tempError = {};
    tempError.name = validateName();
    tempError.route = validateRoute();
    if (tempError.name === "") {
      delete tempError.name;
    }
    if (tempError.type === "") {
      delete tempError.type;
    }
    if (tempError.route === "") {
      delete tempError.route;
    }
    if (Object.keys(tempError).length > 0) {
      setSave(false);
    } else if (isNew.name || isNew.route) {
      setSave(false);
    } else {
      setSave(true);
    }
    setErrors(tempError);
  };

  const validateName = () => {
    if (!isNew.name) {
      if (!edit?.name) {
        return t("errorEmptyName");
      } else if (!validateNameCharacters(edit?.name)) {
        return t("errorName");
      } else if (
        arrayRoutes?.some((e) => e.name === edit?.name && e._id !== edit._id)
      ) {
        return t("errorNameExist");
      }
    }
    return "";
  };

  const validateRoute = () => {
    if (!isNew.route) {
      if (!edit?.route || edit.route === "") {
        return t("errorEmptyRoute");
      } else if (!validateUrlRoute(edit.route)) {
        return t("errorRoute");
      }
    }
    return "";
  };

  const handleEditButton = () => {
    if (!editing) {
      setEdit({
        _id: route._id,
        name: route.name,
        type: route.type,
        route: route.route,
        params: route.params,
        options: {
          file: route.options.file,
          filePosition: route.options.filePosition,
        },
        responseMapping: route.responseMapping,
        new: route.new,
        editing: route.editing,
      });
    }
    setEditing(true);
  };

  const handleEditInput = (value, fieldName) => {
    let editRoute = { ...edit };

    if (fieldName == "routeMapperAdd") {
      if (value.originalName === "" || value.mappedName === "") {
        setErrorMapped(
          "Debe completar ambos campos para agregar una respuesta",
        );
        return;
      }

      if (
        edit.responseMapping.some((e) => e.originalName === value.originalName)
      ) {
        setErrorMapped("Ya existe una respuesta con ese nombre");
        return;
      }

      setErrorMapped("");

      tableRef.current.setCreatingRow(false);

      editRoute.responseMapping = [...editRoute.responseMapping, value];
    }

    if (fieldName == "routeMapperDelete") {
      editRoute.responseMapping = editRoute.responseMapping.filter(
        (e) => e._id !== value._id,
      );
    }

    if (fieldName == "routeMapperEdit") {
      if (value.values._id != value.row.original._id) {
        const valuesMappedChanged = editRoute.responseMapping.filter(
          (e) => e._id === value.row.original._id,
        );

        if (
          value.values.originalName === "" ||
          value.values.mappedName === ""
        ) {
          setErrorMapped(
            "Debe completar ambos campos para editar una respuesta",
          );
          return;
        }

        if (
          edit.responseMapping.some(
            (e) =>
              e.originalName === value.values.originalName &&
              e.mappedName === value.values.mappedName,
          )
        ) {
          setErrorMapped("Ya existe una respuesta con ese nombre");
          return;
        }

        setErrorMapped("");

        tableRef.current.setEditingRow(false);

        valuesMappedChanged[0].originalName = value.values.originalName;
        valuesMappedChanged[0].mappedName = value.values.mappedName;
      }
    }

    if (fieldName === "file" || fieldName === "filePosition") {
      editRoute.options = { ...editRoute.options };
      editRoute.options[fieldName] = !editRoute.options[fieldName];
    } else if (!fieldName && Array.isArray(value)) {
      editRoute.params = [...value];
    } else {
      editRoute[fieldName] = value;
      if (isNew.name === true || isNew.route === true) {
        const tempIsNew = { ...isNew };
        if (fieldName === "name") {
          tempIsNew.name = false;
        } else if (fieldName === "route") {
          tempIsNew.route = false;
        }
        setIsNew(tempIsNew);
      }
    }
    setEdit(editRoute);
  };

  const handleSaveEdit = () => {
    saveRoute(edit);
    setEdit({});
    setEditing(false);
    setErrorMapped("");
  };

  const handleCancelEdit = () => {
    tableRef.current.setCreatingRow(false);
    tableRef.current.setEditingRow(false);
    setErrorMapped("");
    setEditing(false);
    setEdit({});
    if (route.new) {
      setEdit({});
      modalDeleteRoute(route);
    } else {
      const originalRoute = {
        name: route.name,
        type: route.type,
        route: route.route,
        params: route.params,
        options: {
          file: route.options.file,
          filePosition: route.options.filePosition,
        },
        responseMapping: route.responseMapping,
        editing: false,
      };
      setEdit(originalRoute);
    }
  };

  const renderButtonsEdit = (id) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}>
        <Button
          id={`saveEditButton_${id}`}
          variant="contained"
          disabled={!save}
          onClick={handleSaveEdit}
          sx={{ margin: "8px" }}>
          {t("saveEditButon")}
        </Button>
        <Button
          id={`cancelEditButton_${id}`}
          variant="contained"
          onClick={handleCancelEdit}
          sx={{ margin: "8px" }}>
          {t("cancelEditButon")}
        </Button>
      </Box>
    );
  };

  const renderSelectType = (type, edit) => {
    return (
      <Select
        id="routeType"
        label={t("routeType")}
        variant="standard"
        disabled={!edit}
        error={errors?.type ? true : false}
        helperText={errors?.type}
        value={type}
        onChange={(e) => handleEditInput(e.target.value, "type")}
        sx={{ minWidth: "20%", marginTop: "2%", marginBottom: "2%" }}>
        <MenuItem value="GET">GET</MenuItem>
        <MenuItem value="POST">POST</MenuItem>
        <MenuItem value="PUT">PUT</MenuItem>
        <MenuItem value="PATCH">PATCH</MenuItem>
        <MenuItem value="DELETE">DELETE</MenuItem>
      </Select>
    );
  };

  return (
    <Grid
      container
      columns={7}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
      <Grid item xs={5}>
        <TextField
          value={editing ? edit?.name : route.name}
          disabled={!editing}
          onChange={(e) => handleEditInput(e.target.value, "name")}
          label={t("nameRoute")}
          error={errors?.name ? true : false}
          helperText={errors?.name}
          fullWidth
          variant="standard"
        />
        {renderSelectType(editing ? edit?.type : route.type, editing)}
        <TextField
          value={editing ? edit?.route : route.route}
          disabled={!editing}
          onChange={(e) => handleEditInput(e.target.value, "route")}
          label={t("route")}
          error={errors?.route ? true : false}
          helperText={errors?.route}
          fullWidth
          variant="standard"
        />
        <ChipInput
          name={t("routeParams")}
          label={t("routeParams")}
          fullWidth
          disabled={!editing}
          sx={{ marginTop: "15px", marginBottom: "25px" }}
          value={editing ? edit?.params : route.params}
          updateProps={(e) => handleEditInput(e)}
        />
      </Grid>
      <Grid
        item
        xs={1}
        rows={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <Grid
          item
          xs={2}
          columns={24}
          sx={{ display: "flex", flexDirection: "row" }}>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", flexDirection: "row", marginRight: "30%" }}>
            <Tooltip
              title={
                userPermissions ? t("editTooltip") : t("noPermissionMessage")
              }
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton
                  id="editRouteButton"
                  disabled={!userPermissions}
                  onClick={() => handleEditButton()}
                  sx={{ height: "50%" }}>
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", flexDirection: "row", marginLeft: "30%" }}>
            <Tooltip
              title={
                userPermissions ? t("deleteTooltip") : t("noPermissionMessage")
              }
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton
                  id="deleteRouteButton"
                  disabled={!userPermissions}
                  onClick={() => modalDeleteRoute(route)}
                  sx={{ height: "50%" }}>
                  <Delete
                    sx={{
                      color: userPermissions ? "deleteIcon.color" : "grey",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <TableMapper
        editing={editing}
        edit={edit}
        route={route}
        handleEditInput={handleEditInput}
        errorMapped={errorMapped}
        tableRef={tableRef}
      />
      <FormGroup>
        <FormControlLabel
          disabled={!editing}
          control={
            <Checkbox
              onChange={(e) => handleEditInput(e.target.value, "file")}
              checked={editing ? edit?.options?.file : route.options?.file}
            />
          }
          label={t("routeFile")}
        />
        <FormControlLabel
          disabled={!editing}
          control={
            <Checkbox
              onChange={(e) =>
                handleEditInput(e.target.value, "filePosition")
              }
              checked={
                editing
                  ? edit?.options?.filePosition
                  : route.options?.filePosition
              }
            />
          }
          label={t("routeFilePosition")}
        />
      </FormGroup>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <ApiTest
          type={route.type}
          host={route.host}
          route={route.route}
          params={route.params}
        />
      </Grid>
      {editing ? renderButtonsEdit(_id) : null}
    </Grid>
  );
};

export default RouteItem;
