import "./Powerbi.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import React, { useState, useEffect } from "react";
import { get } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Powerbi = () => {
  const [urlPowerBi, setUrlPowerBi] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const client = useSelector((state) => state.client);
  
  useEffect(() => {
    getPowerBiReport();
  }, [client.name]);

  const getPowerBiReport = () => {
    get("/clients/reportPowerBi")
      .then(async (data) => {
        setUrlPowerBi(data);
      })
      .catch(() => {
        enqueueSnackbar("Error getting Power Bi report", {
          variant: "error",
        });
      });
  };

  return (
    <div className="Powerbi">
      <header className="Powerbi-header">
        {urlPowerBi && (<PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            embedUrl: urlPowerBi,
            accessToken: "",
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: true,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"Embed-container"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />)}
        {!urlPowerBi && (
          <Typography sx={{fontSize: "50px"}}>
                No se pudo encontrar el reporte para ese cliente
          </Typography>
        )}
      </header>
    </div>
  );
};

export default Powerbi;
