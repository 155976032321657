import React, { useState, useEffect, useMemo } from "react";
import { withSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { get, post, put, remove } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Button } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./EditModal.component";
import RefreshIcon from "@mui/icons-material/Refresh";
import ApiComponent from "./ApiComponents/ApiComponent";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const Faq = () => {
  const { t, i18n } = useTranslation("endpoints");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const theme = useSelector((state) => state.theme);

  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "endpoints" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const columns = useMemo(
    () => [
      {
        header: t("endpoints:hostName"),
        accessorKey: "name",
        size: 100,
      },
      {
        header: t("endpoints:host"),
        accessorKey: "host",
        size: 100,
      },
    ],
    [i18n.language],
  );

  const [data, setData] = useState([]);
  const client = useSelector((state) => state.client);

  useEffect(() => {
    getUserPermisions();
    getEndpoints();
  }, [client.name]);

  const getEndpoints = () => {
    get("/endpoints")
      .then(async (data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("endpoints:errorGettingEndpoints"), {
          variant: "error",
        });
      });
  };

  const newFaq = (row) => {
    const body = {
      nameHost: row.name,
      host: row.host,
      auth: row.auth ?? [],
      route: row.route?? [],
    };
    if (body.type === "" || body.type === undefined) {
      body.type = "POST";
    }
    post("/endpoints", body)
      .then(() => {
        enqueueSnackbar(t("endpoints:newEndpoint"), {
          variant: "success",
        });
        getEndpoints();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(t("endpoints:errorCreatingEndpoints", errorMessage), {
          variant: "error",
        });
      });
  };

  const updateFaq = (row, values) => {
    const body = {
      _id: row.original?._id ?? row._id,
      nameHost: values.name,
      host: values.host,
      auths: values.auths ?? row.original.auth,
      routes: values.routes ?? row.original.routes,
    };
    put("/endpoints", body)
      .then(async () => {
        enqueueSnackbar(t("endpoints:endpointUpdated"), {
          variant: "success",
        });
        getEndpoints();
      })
      .catch(() => {
        enqueueSnackbar(t("endpoints:errorUpdatingEndpoint"), {
          variant: "error",
        });
      });
  };

  const deleteFaq = (row) => {
    const body = {
      _id: row.original._id,
    };
    remove("/endpoints", body)
      .then(async () => {
        enqueueSnackbar(t("endpoints:endpointUpdated"), {
          variant: "success",
        });
        setDeleteModalOpen(false);
        getEndpoints();
      })
      .catch(() => {
        enqueueSnackbar(t("endpoints:errorDeletingEndpoint"), {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("endpoints")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            positionActionsColumn="last"
            columns={columns}
            data={data}
            renderDetailPanel={(row)=> {
              const endpoint = row.row.original;
              return (
                <ApiComponent
                  key={endpoint._id}
                  endpoint={endpoint}
                  updateEndpoint={updateFaq}
                  userPermissions={userPermissions}
                />
              );   
            }}
            enableColumnOrdering
            enableGlobalFilter={false}
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateFaq}
            enableDelete
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions
                      ? t("endpoints:editButton")
                      : t("endpoints:noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      onClick={() => {
                        setEditModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("endpoints:deleteButton")
                      : t("endpoints:noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderToolbarInternalActions={({ table }) => (
              <Box>
                <Tooltip arrow title={t("endpoints:tooltipRefresh")}>
                  <IconButton
                    onClick={() => {
                      getEndpoints();
                    }}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Tooltip
                arrow
                placement="right"
                title={
                  userPermissions ? "" : t("endpoints:noPermissionTooltip")
                }>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      setEditModalOpen(true);
                      setTypeModal("new");
                    }}
                    variant="contained"
                    disabled={!userPermissions}>
                    {t("endpoints:addEndpoint")}
                  </Button>
                </span>
              </Tooltip>
            )}
          />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteFaq(editRow);
        }}
        title={t("endpoints:tittleDeleteModal")}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          getEndpoints();
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        data={{
          allData: data,
          _id: editRow?.original?._id,
          name: editRow?.original?.name,
          host: editRow?.original?.host,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newFaq(values);
            setEditModalOpen(false);
            setTypeModal(null);
          } else {
            updateFaq(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
      />
    </React.Fragment>
  );
};

export default withTranslation()(withSnackbar(Faq));
