(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("powerbi-client"), require("lodash.isequal"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "powerbi-client", "lodash.isequal"], factory);
	else if(typeof exports === 'object')
		exports["powerbi-client-react"] = factory(require("react"), require("powerbi-client"), require("lodash.isequal"));
	else
		root["powerbi-client-react"] = factory(root["react"], root["powerbi-client"], root["lodash.isequal"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__164__, __WEBPACK_EXTERNAL_MODULE__695__) => {
return 