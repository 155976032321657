import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";


const DateRange = (props) => {
  const minDate =
    props.allowFuture && props.allowFuture === true ? dayjs() : null;
  const [maxDate, setMaxDate] = useState();
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [maxStartDate, setMaxStartDate] = useState();
  const [minStartDate, setMinStartDate] = useState();
  const [dates, setDates] = useState({
    startDate: props.startDate
      ? dayjs(props.startDate).startOf("day")
      : dayjs().startOf("day"),
    endDate: props.endDate
      ? dayjs(props.endDate).endOf("day")
      : dayjs().endOf("day"),
  });
  const [validDates, setValidDates] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (props.allowFuture) {
      dates.endDate
        ? setMaxDate(null)
        : setMaxDate(dayjs(dates.endDate).endOf("day"));
      setMaxStartDate(dates.endDate);
    } else {
      setMaxDate(dayjs().endOf("day"));
      setMaxStartDate(dates.endDate > dayjs() ? dayjs() : dates.endDate);
    }

    if (props.maxOneWeek && dates.endDate) {
      setMinStartDate(dayjs(dates.endDate).subtract(7, "day"));
    } else {
      setMinStartDate(null);
    }

    checkValid();
  }, [dates.startDate, dates.endDate]);

  useEffect(() => {
    const newStartDate = props.startDate
      ? dayjs(props.startDate).startOf("day")
      : dayjs().startOf("day");
    const newEndDate = props.endDate
      ? dayjs(props.endDate).endOf("day")
      : dayjs().endOf("day");

    if (!dates.startDate.isSame(newStartDate) || !dates.endDate.isSame(newEndDate)) {
      setDates({
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
  }, [props.startDate, props.endDate]);
  
  const checkValid = () => {
    const tempValidDates = {
      startDate: validDates.startDate,
      endDate: validDates.endDate,
      range: validDates.range,
    };
    const temporalDate = {
      startDate: dates.startDate,
      endDate: dates.endDate,
      valid: true,
    };
    tempValidDates.startDate = validDate(dates.startDate);
    tempValidDates.endDate = validDate(dates.endDate);
    tempValidDates.range = withinRange(dates.startDate, dates.endDate);
    setValidDates(tempValidDates);
    if (
      !tempValidDates.startDate ||
      !tempValidDates.endDate ||
      !tempValidDates.range
    ) {
      temporalDate.valid = false;
    } else {
      temporalDate.valid = true;
    }

    props.onDateSelected(temporalDate);
    if (
      props.setError &&
      (!tempValidDates.startDate ||
        !tempValidDates.endDate ||
        !tempValidDates.range)
    ) {
      const errors = { ...props.errors, date: "error" };
      props.setError(errors);
    } else if (props.setError) {
      const errors = { ...props.errors };
      delete errors.date;
      props.setError(errors);
    }
  };

  const formatAndCheckDate = (date) => {
    if (!date) return null;

    return dayjs.isDayjs(date) ? date : dayjs(date);
  };

  const updateDateStart = (startDate) => {
    startDate = formatAndCheckDate(startDate);
    if (typeof startDate === "string") {
      startDate = dayjs(new Date(startDate));
    } else if (startDate) {
      startDate = dayjs(startDate);
    } else {
      startDate = null;
    }

    let tempDates = {
      startDate: startDate,
      endDate: dates.endDate,
    };
    setDates(tempDates);
  };

  const updateDateEnd = (endDate) => {
    endDate = formatAndCheckDate(endDate);
    if (typeof endDate === "string") {
      endDate = dayjs(new Date(endDate));
    } else if (endDate) {
      endDate = dayjs(endDate);
    } else {
      endDate = null;
    } 

    let tempDates = {
      startDate: dates.startDate,
      endDate: endDate,
    };
    setDates(tempDates);
  };

  function validDate(date) {
    const currentDate = dayjs();
    if (props.allowFuture && props.allowFuture === true) {
      return (
        date !== null &&
        (date?.isAfter(currentDate, "day") || date?.isSame(currentDate, "day"))
      );
    } else {
      let response = dayjs(date).isValid();
      return response;
    }
  }

  function withinRange(startDate, endDate) {
    if (!startDate || !endDate) return false;
    if (startDate > endDate) {
      return false;
    }
    if (
      !props.allowFuture &&
      (startDate > dayjs().endOf("day") || endDate > dayjs().endOf("day"))
    ) {
      return false;
    }
    return !(props.maxOneWeek && endDate.diff(startDate, "day") > 7);
  }

  const getLanguage = () => {
    const storedLanguage = localStorage.getItem("lang");
    if (storedLanguage === "ES") {
      return "es";
    } else {
      return "en";
    }
  };

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={getLanguage()}>
        <div style={{ display: "flex" }}>
          <div style={{ margin: "5px" }}>
            <DatePicker
              label={t("dateRange:startDate")}
              format="DD/MM/YYYY"
              minDate={minStartDate || minDate}
              maxDate={maxStartDate}
              value={dates.startDate}
              onChange={(newValue) => updateDateStart(newValue ? newValue.toDate() : null)}
              disabled={props.disabled}
              open={startDateOpen}
              onOpen={() => setStartDateOpen(true)}
              onClose={() => setStartDateOpen(false)}
              slotProps={{
                textField: {
                  readOnly: true,
                  error: !validDates.startDate || !validDates.range,
                  InputProps: {
                    style: { cursor: "pointer" },
                  },
                  onClick: () => setStartDateOpen(true),
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                },
              }}
            />
          </div>
          <div style={{ margin: "5px" }}>
            <DatePicker
              label={t("dateRange:endDate")}
              format="DD/MM/YYYY"
              minDate={dates.startDate}
              maxDate={maxDate}
              value={dates.endDate ? dayjs(dates.endDate) : ""}
              onChange={(newValue) => updateDateEnd(newValue ? newValue.toDate() : null)}
              disabled={props.disabled}
              open={endDateOpen}
              onOpen={() => setEndDateOpen(true)}
              onClose={() => setEndDateOpen(false)}
              slotProps={{
                textField: {
                  readOnly: true,
                  error: !validDates.endDate || !validDates.range,
                  InputProps: {
                    style: { cursor: "pointer" },
                  },
                  onClick: () => setEndDateOpen(true),
                  onKeyDown: (e) => {
                    e.preventDefault();
                  },
                },
              }}
            />
          </div>
        </div>
        <span
          style={{
            color: "red",
            fontSize: 12,
            display: "block",
            margin: "0px 5px 0px 5px",
          }}>
          {!validDates.startDate || !validDates.endDate || !validDates.range
            ? t("dateRange:errorSelectionDate")
            : ""}
        </span>
      </LocalizationProvider>
    </React.Fragment>
  );
};

DateRange.propTypes = {
  allowFuture: PropTypes.bool,
  maxOneWeek: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDateSelected: PropTypes.func,
  setError: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};


export default React.memo(DateRange);