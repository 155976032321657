import React, { useEffect, useState } from "react";
import TransferTable from "./TransferTable/TransferTable.component";
import CampaignsTable from "./CampaignsTable/CampaignsTable.component";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const providersData = [
  {
    name: "ChattigoProvider",
    value: "chattigo",
  },
  {
    name: "GupshupProvider",
    value: "gupshup",
  },
  {
    name: "MetaProvider",
    value: "meta",
  },
  {
    name: "TeamsProvider",
    value: "teams",
  },
  {
    name: "TelepromProvider",
    value: "teleprom",
  },
];

const Transfers = () => {
  let auth = useSelector((state) => state.auth);
  const selectedClient = useSelector((state) => state.client);
  let [userPermissions, setUserPermissions] = useState({
  });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
  
    let transferPermission = user.permissions.find(
      (permission) => permission.name === "transferTable"
    );
    let campaignPermission = user.permissions.find(
      (permission) => permission.name === "campaigns"
    );
  
    setUserPermissions({
      transfer: transferPermission ? transferPermission.fullAccess : null,
      campaign: campaignPermission ? campaignPermission.fullAccess : null,
    });
  };

  useEffect(() => {
    getUserPermisions();
  }, []);

  return (
    <div style={{ display: isSmallScreen ? "block" : "flex" }}>
      {userPermissions.transfer !== null && userPermissions.transfer !== undefined && (
        <Box
          id="transfer-table-container"
          sx={{
            height: "100%",
            width: isSmallScreen || userPermissions.campaign === null || userPermissions.campaign === undefined ? "100%" : "54%",
            padding: "10px",
            borderRadius: "25px",
            backgroundColor: "containerTableBackground.backgroundColor",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            marginBottom: isSmallScreen ? "2%" : "0",
          }}>
          <TransferTable
            providersData={providersData}
            userPermissions={userPermissions.transfer}
          />
        </Box>
      )}
      {userPermissions.campaign !== null && userPermissions.campaign !== undefined && (
        <Box
          id="campaigns-table-container"
          sx={{

            width: isSmallScreen || userPermissions.transfer === null || userPermissions.transfer === undefined ? "100%" : "46%",
            marginLeft: isSmallScreen ? "" : "2%",
            marginTop: isSmallScreen ? "2%" : "",
            padding: "10px",
            borderRadius: "25px",
            backgroundColor: "containerTableBackground.backgroundColor",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}>
          <CampaignsTable
            providersData={providersData}
            userPermissions={userPermissions.campaign}
            clientSelected={selectedClient}
          />
        </Box>
      )}
    </div>
  );
};

export default Transfers;
